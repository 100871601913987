import { generateBreadcrumbJson } from "../../components/generateBreadcrumb";

export const generateBreadcrumb = (props) => {
    if ( props.initialize ) {
      window.INTELNAV.dashboard.signOut = function(){
         props.logout();
      } 
    }
    if (window.intel && window.intel.breadcrumbFromJSON) {
        const breadcrumbJson = generateBreadcrumbJson(props.EITitle, props.pageName, props.homeURL);
        window.intel.breadcrumbFromJSON.createFromJson(breadcrumbJson);
        window.intel.breadcrumbbar.initialize();
    }
};

