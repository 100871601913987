import axios from 'axios';
const { tokenUrl, apigeeKey, apigeeSecret } = require('../constants/config');
const token = async () => {

    //Get token
    try {
        const response = await axios({
            method: 'post',
            url: tokenUrl,
            responseType: 'json',
            data: {
                grant_type: 'client_credentials',
                client_id: apigeeKey,
                client_secret: apigeeSecret,
                scope: `api://${apigeeKey}/.default`
            },
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            },
            proxy: {
                'host': 'proxy-dmz.intel.com',
                'port': 912
            }
        })
       
        return response;

    }
    catch (error) {
        return error
    }

    
  };
  
  export default token;