import axios from 'axios';
import token from '../common/axiosHelper';
const { apigeeUrl, selfUIEnv } = require('../constants/config');

const tokenResponse = await token();
const getCatalogList = async () => {
    if(tokenResponse.status === 200) {

        try {
            const response = await axios.get(`${apigeeUrl}/edge-services?env=${selfUIEnv}&timestamp=${new Date().getTime()}`,{ headers: { 'Authorization': `Bearer ${tokenResponse.data.access_token}`, 'Content-Type': 'application/json'}});
            return response;
        }
        catch(error) {
            return error.response;
        }
        
    }
    else {
        return tokenResponse;
    }
    
};

export const homePageService = {
    getCatalogList,
};