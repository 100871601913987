import "./ExploreTile.scss";
import AIMachineLearning from "../../assets/cpu-brain.svg";
import dataIngestion from "../../assets/database.svg";
import Application from "../../assets/application.svg";
import camera from "../../assets/camera.svg";
import { useNavigate } from 'react-router-dom';
import {  Column, Grid } from '@carbon/react';

const ExploreTile = (props) => {

    const navigate = useNavigate();

    const handleClick = (topic) => {
        navigate(`/software-catalog?topic=${topic}`);
    };

    return (
        <>
            <div className="explore_tile_wrapper">
                <Grid condensed={true}>
                    <Column lg={16} md={8} sm={4}>
                <p className="explore_title" >Explore by Topic</p>
                {/* <div className="explore_tile_grid"> */}

                
                <Grid condensed={true} style={{ marginTop: '16px', gap: '16px', alignItems: 'center', padding: 0 }}>
                    <Column lg={4} md={4} sm={4}>
                    <div tabIndex="0" aria-label="explore-topic-tile-1" className="explore_topic_tile_wrapper" onClick={() => handleClick("AI and Machine Learning")}>
                        <div className="explore_topic_tile_content">
                            <div className="explore_tile">
                                <img src={AIMachineLearning} alt="AI and Machine Learning" className="explore_tile_icon" />
                            </div>
                            <p className="explore_tile explore_tile_title">AI and Machine Learning</p>
                        </div>

                    </div>
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                    <div tabIndex="0" aria-label="explore-topic-tile-2" className="explore_topic_tile_wrapper" onClick={() => handleClick("Application Service")}>
                        <div className="explore_topic_tile_content">
                            <div className="explore_tile">
                                <img src={Application} alt="Application Service" className="explore_tile_icon" />
                            </div>
                            <p className="explore_tile explore_tile_title">Application Service</p>
                        </div>
                    </div>
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                    <div tabIndex="0" aria-label="explore-topic-tile-3" className="explore_topic_tile_wrapper" onClick={() => handleClick("Data Ingestion")}>
                        <div className="explore_topic_tile_content">
                            <div className="explore_tile">
                                <img src={dataIngestion} alt="Data Ingestion" className="explore_tile_icon" />
                            </div>
                            <p className="explore_tile explore_tile_title">Data Ingestion</p>
                        </div>
                    </div>
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                    <div tabIndex="0" aria-label="explore-topic-tile-4" className="explore_topic_tile_wrapper" onClick={() => handleClick("Visualization")}>
                        <div className="explore_topic_tile_content">
                            <div className="explore_tile">
                                <img src={camera} alt="Analytics" className="explore_tile_icon" />
                            </div>
                            <p className="explore_tile explore_tile_title">Visualization</p>
                        </div>
                    </div>
                    </Column>
                </Grid>
                {/* </div> */}
                </Column>
                </Grid>
            </div>
        </>
    )
}
export default ExploreTile;