import { useState, useEffect, useRef } from 'react';
import './Overview.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import {  Loading, Tag, Tabs, Tab, TabList, Dropdown, Grid, Column, Stack, Heading, Layer,Link } from '@carbon/react';
import { overviewPageService } from '../../services/overviewPageService';
import parse from 'html-react-parser';
import Header from '../common/Header';
import { HeroBanner } from '../common/HeroBanner';
import Recipes from "../../selector-tool-ui-src/pages/Packages/Packages"
import App from '../../selector-tool-ui-src/App'
const Overview = (props) => {

  const [onPageLoad, setOnPageLoad] = useState(false);
  const [edgeServiceId, setEdgeServiceId] = useState('');
  const [edgeServiceDetails, setEdgeServiceDetails] = useState({});
  const [overviewContent, setOverviewContent] = useState('');
  const [howItWorksContent, setHowItWorksContent] = useState('');
  const [updatedDate, setUpdatedDate] = useState("");
  const [multipleVersions, setMultipleVersions] = useState([]);
  const [showGetStarted, setShowGetStarted] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  // const [showGitHubUrl, setShowGitHubUrl] = useState(false);
  const [showDocumentation, setShowDocumentation] = useState(false);
  const [showUserGuide, setShowUserGuide] = useState(false);
  const [showAPIDocLink, setShowAPIDocLink] = useState(false);
  const [githubLanguages, setGithubLanguages] = useState({});
  const [currentVersion, setCurrentVersion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyResponse, setIsEmptyResponse] = useState(false);
  const [currentVersionObject, setCurrentVersionObject] = useState();

  const overviewRef = useRef();
  const howItWorksRef = useRef();

  // let location = useLocation();
  const search = useLocation().search;
  const navigate = useNavigate();

  const callFunctions = async (microserviceType, microserviceNameForUrl) => {

    //Check if microserviceType and microserviceNameForUrl is present
    if (microserviceType !== undefined && microserviceType !== null && microserviceNameForUrl !== undefined && microserviceNameForUrl !== null) {

      //Get the Edge Service Id
      setIsLoading(true);
      let edgeServicesMetadataResponse = await overviewPageService.getEdgeServiceMetadata(microserviceType, microserviceNameForUrl);
      let data;

      if (edgeServicesMetadataResponse.status === 200) {
        if (edgeServicesMetadataResponse.data) {
          data = edgeServicesMetadataResponse.data;

          if (data.length > 0) {
            data.forEach(element => {

              if (element.microserviceType === 'helm') {
                let versionObject = {
                  id: element.id,
                  text: element.chartVersion
                }

                //Check if version already present
                let versionExists = false;
                multipleVersions.forEach(item => {
                  if (element.chartVersion === item.text) {
                    versionExists = true;
                  }
                })

                if (!versionExists) {
                  multipleVersions.push(versionObject);
                }
              }
              else if (element.microserviceType === 'container') {
                let versionObject = {
                  id: element.id,
                  text: element.imageTag
                }

                //Check if version already present
                let versionExists = false;
                multipleVersions.forEach(item => {
                  if (element.imageTag === item.text) {
                    versionExists = true;
                  }
                })

                if (!versionExists) {
                  multipleVersions.push(versionObject);
                }
              }
              else if (element.microserviceType === 'recipe') {
                let versionObject = {
                  id: element.id,
                  text: element.version
                }

                //Check if version already present
                let versionExists = false;
                multipleVersions.forEach(item => {
                  if (element.version === item.text) {
                    versionExists = true;
                  }
                })

                if (!versionExists) {
                  multipleVersions.push(versionObject);
                }
              }


            });
            setCurrentVersion(multipleVersions[0].text);
            setEdgeServiceId(multipleVersions[0].id);
            //setCurrentVersionObject({ id: multipleVersions[0].id, text: multipleVersions[0].text });
            setCurrentVersionObject(multipleVersions[0]);
            await getContents(multipleVersions[0].id);
          }
          else if (data.length === 0) {

            setIsEmptyResponse(true);
            setIsLoading(false);
            navigate("/PageNotFound");

          }
        }
        else {
          setIsEmptyResponse(true);
          setIsLoading(false);
          navigate("/PageNotFound");
        }
      }
      else {
        setIsLoading(false);
        navigate("/PageNotFound");
      }

    }

  }

  const updateVersionAndGetDetails = async (event) => {
    multipleVersions.forEach(element => {
      if (element.id === event.selectedItem.id) {
        setEdgeServiceId(event.selectedItem.id);
        setCurrentVersionObject(element);
        setCurrentVersion(element.text);

      }
    })
    await getContents(event.selectedItem.id);
  }

  const getContents = async (edgeServiceId) => {

    setIsLoading(true);
    let responseContents = await overviewPageService.getEdgeServiceContentsUsingId(edgeServiceId);

    if (responseContents.status === 200) {
      const data = responseContents.data;
      setIsLoading(false);
      if (data) {

        setEdgeServiceDetails(data);
        setOverviewContent(data.content.overview);
        setHowItWorksContent(data.content.howItWorks);

        const modifiedOn = data.modifiedOn.split('T')[0];
        const date = modifiedOn.split('-');
        const year = date[0];
        const month = date[1];
        const day = date[2];
        const dateFormat = month + '/' + day + '/' + year;
        setUpdatedDate(dateFormat);
        const selectorToolRecipeId = (data)=> {
          const lastSlashIndex = data.downloadLink.split('/')[3];
          return '/package/' + lastSlashIndex;
        }
        console.log('selectorToolRecipeId(data)', selectorToolRecipeId(data))
        setEdgeServiceDetails((edgeServiceDetails) => ({ ...edgeServiceDetails, selectorToolRecipeId: selectorToolRecipeId(data) }))
        //Check if Download and Github link are provided
        // if (data.publicGithubUrl === "" || data.publicGithubUrl === null || data.publicGithubUrl === undefined) {
        //   setShowGitHubUrl(false);
        // }
        // else {
        //   setShowGitHubUrl(true);
        //   setShowGetStarted(true);
        // }

        if (data.downloadLink === "" || data.downloadLink === null || data.downloadLink === undefined) {
          setShowDownload(false);
        }
        else {
          setShowDownload(true);
          setShowGetStarted(true);
        }

        //Check for documentation
        if ((data.userGuideLink === "" || data.userGuideLink === undefined || data.userGuideLink === null) && (data.apiDocumentationLink === "" || data.apiDocumentationLink === undefined || data.apiDocumentationLink === null)) {
          setShowDocumentation(false);
        }
        else if ((data.userGuideLink !== "" && data.userGuideLink !== undefined && data.userGuideLink !== null) || (data.apiDocumentationLink !== "" && data.apiDocumentationLink !== undefined && data.apiDocumentationLink !== null)) {
          setShowDocumentation(true);
        }


        if (data.userGuideLink === "" || data.userGuideLink === undefined || data.userGuideLink === null) {
          setShowUserGuide(false);
        }
        else {
          setShowUserGuide(true);
        }

        if (data.apiDocumentationLink === "" || data.apiDocumentationLink === undefined || data.apiDocumentationLink === null) {
          setShowAPIDocLink(false);
        }
        else {
          setShowAPIDocLink(true);
        }


        await getGithubLanguages(edgeServiceId);
      }
      else {
        navigate("/PageNotFound");
      }
    }
    else {
      navigate("/PageNotFound");
    }



  }

  const getGithubLanguages = async (id) => {
    let gitResponse = await overviewPageService.getGithubLanguages(id);
    setGithubLanguages(gitResponse.data.languageInfo);
  }

  useEffect(() => {

    const params = new URLSearchParams(search);
    let microserviceType;
    let microserviceNameForUrl;
    if (params) {
      if (params.get('microserviceType')) {
        microserviceType = params.get('microserviceType');
      }
      else {
        navigate("/PageNotFound");
      }
      if (params.get('microserviceNameForUrl')) {
        microserviceNameForUrl = params.get('microserviceNameForUrl');
      }
      else {
        navigate("/PageNotFound");
      }
    }

    callFunctions(microserviceType, microserviceNameForUrl);


  }, [onPageLoad])

  const handleBackClick = () => {
    navigate(`/software-catalog`);
  };

  return (

    <>
      <Header />
      <div className="overview-header">
        <div className="back-section">
          <div className="back-button">
            <Link tabIndex="0" className="back-button-link" onClick={handleBackClick}>
              <i className="spark-icon spark-icon-light spark-icon-chevron-small-left back-button-icon"></i>
              <p className="back-button-text">Back</p>
            </Link>
          </div>
         
        </div>
      </div>
      {!isLoading ?
        <>
          <section className="overview-banner">
          
            <HeroBanner
              variant="primary">
             <Grid condensed style={{
                gap: '16px',
                alignItems: 'center'
              }}>
                <Column lg={16} md={8} sm={4}>
                  <Stack gap={3} orientation="vertical">

                    <div className="overview-banner-title-and-tags">
                      <Heading className="overview-banner-title">{edgeServiceDetails.displayName}</Heading>
                      <div className="overview-banner-tags-section">
                      <Layer>
                      <Stack orientation="horizontal" gap={2}>
                        <div className='overview-banner-icon'>
                        <div className="overview-banner-tags-icon">
                          {edgeServiceDetails.contentType === 'Sample Application' ? <i className="spark-icon spark-icon-light spark-icon-stack overview-banner-tags-icon"></i>
                          : edgeServiceDetails.contentType === 'Reference Implementation' ? <i className="spark-icon spark-icon-light spark-icon-build-cube overview-banner-tags-icon"></i>
                          : edgeServiceDetails.contentType === 'Microservice' ? <i className="spark-icon spark-icon-light spark-icon-cube-detached overview-banner-tags-icon"></i>: null
                        }
                          </div>
                        </div>
                        {edgeServiceDetails.contentType && edgeServiceDetails.contentType.length > 0 ? (
                          <div className="overview-banner-tag-section">
                            <div className="overview-banner-tag-content">
                              <div className="overview-banner-tag-label-container">
                                <Tag size="sm" className="overview-banner-tag-text">
                                  {edgeServiceDetails.contentType}
                                </Tag>
                              </div>
                            </div>
                          </div>

                        ) : null}
                        {edgeServiceDetails.industry && edgeServiceDetails.industry.length > 0 ? <>{edgeServiceDetails.industry.map((tag, index) => (
                          (<div className="overview-banner-tag-section">
                            <div className="overview-banner-tag-content">
                              <div className="overview-banner-tag-label-container">
                                <Tag size="sm" className="overview-banner-tag-text">
                                  {tag}
                                </Tag>
                              </div>
                            </div>
                          </div>)

                        ))}</>
                          : null}
                        {edgeServiceDetails.domain && edgeServiceDetails.domain.length > 0 ? <>{edgeServiceDetails.domain.map((tag, index) => (
                          <div className="overview-banner-tag-section">
                            <div className="overview-banner-tag-content">
                              <div className="overview-banner-tag-label-container">
                                <Tag size="sm" className="overview-banner-tag-text">
                                  {tag}
                                </Tag>
                              </div>
                            </div>
                          </div>
                        ))}</>
                          : null}
                          </Stack>
                          </Layer>
                      </div>
                    </div>
                  </Stack>
                  </Column>
                  </Grid>

            </HeroBanner>
           
          </section>

          <Grid className="overview-body" condensed={true} style={{ gap: '16px'}}>
            <Column lg={16} md={8} sm={4}>
            <Tabs className="overview-body-tabs-section">
              <TabList className="overview-body-tabs">
                <Tab className="overview-body-tabs-items" onClick={(e) => {
                  e.preventDefault();
                  overviewRef.current?.scrollIntoView({
                  block: "start", inline: "nearest",
                    behavior: 'smooth'
                  });
                }}>
                  <div className="overview-body-tabs-items-text-overflow">
                    <p className="overview-body-tabs-items-text">Overview</p>
                  </div>
                </Tab>
                <Tab className="overview-body-tabs-items" onClick={(e) => {
                  e.preventDefault();
                  howItWorksRef.current?.scrollIntoView({
                    block: "start", inline: "nearest",
                    behavior: 'smooth'
                  });
                }}>
                  <div className="overview-body-tabs-items-text-overflow">
                    <p className="overview-body-tabs-items-text">How It Works</p>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
            </Column>
            
            <Column lg={16} md={8} sm={4}>
            <Grid className="overview-body-top-block" condensed={true} style={{ gap: '32px', padding: 0 }}>
              <Column lg={12} md={4} sm={4}>
              <div className='top-block-left-body'>
                <div className='block-overview-section'>
                  <div className='block-overview'>
                    <div className='overview-title'>
                      <h2 ref={overviewRef} id="overview" className='spark-heading-size-m overview-title-text'>Overview</h2>
                    </div>
                    <div className='overview-description'>
                      <div className='overview-description-text'>{parse(overviewContent)}</div>
                    </div>
                  </div>
                </div>
                <div tabIndex="0" className='block-howitworks-section'>
                  <div className='block-howitworks'>
                    <div className='block-howitworks-title'>
                      <h2 ref={howItWorksRef} id="howitworks" className='spark-heading-size-m block-howitworks-title-text'>How It Works</h2>
                    </div>
                    <div className='block-howitworks-description'>
                      <p className='block-howitworks-description-text'>{parse(howItWorksContent)}</p>
                    </div>
                  </div>
                </div>
              </div>
              </Column>
              <Column lg={4} md={4} sm={4}>
              <div className='top-block-right-sidebar'>
                <div className='top-block-right-sidebar-general-section'>
                  <div className='top-block-right-sidebar-project-info'>
                    <div className='top-block-right-sidebar-project-info-content'>
                      <div className='top-block-right-sidebar-project-info-list-item'>
                        <div className='top-block-right-sidebar-project-info-list-item-text-section'>
                          <p className='top-block-right-sidebar-project-info-list-item-text-title'>Author</p>
                          <p className='top-block-right-sidebar-project-info-list-item-text-value'>Intel</p>
                        </div>
                      </div>
                      <div className='top-block-right-sidebar-project-info-list-item'>
                        <div className='top-block-right-sidebar-project-info-list-item-text-section'>
                          <p className='top-block-right-sidebar-project-info-list-item-text-title'>Updated</p>
                          <p className='top-block-right-sidebar-project-info-list-item-text-value'>{updatedDate}</p>
                        </div>
                      </div>
                      <div className='top-block-right-sidebar-project-info-list-item'>
                        <div className='top-block-right-sidebar-project-info-list-item-text-section'>
                          <p className='top-block-right-sidebar-project-info-list-item-text-title'>Version</p>
                          <div className='top-block-right-sidebar-project-info-list-item-text-dropdown'><Dropdown aria-label="version-dropdown" id="version" className='top-block-right-sidebar-project-info-list-item-text-dropdown-value' size='md' itemToString={(item) => (item ? item.text : '')} items={multipleVersions} onChange={updateVersionAndGetDetails} initialSelectedItem={multipleVersions[0]} selectedItem={currentVersionObject} /></div>
                          <div className='top-block-right-sidebar-project-info-list-item-text-dropdown'>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showGetStarted ? <div className='top-block-right-sidebar-get-started-section'>
                  <div className='top-block-right-sidebar-get-started-heading'>
                    <div className='top-block-right-sidebar-get-started-heading-content'>
                      <div className='top-block-right-sidebar-get-started-heading-content-title'>
                        <p className='top-block-right-sidebar-get-started-heading-content-title-text'>Get Started</p>
                      </div>
                    </div>
                  </div>
                  <div className='top-block-right-sidebar-get-started-body'>
                        <div className='top-block-right-sidebar-get-started-body-content'>
                          <div className='top-block-right-sidebar-get-started-body-content-list'>
                            {
                              edgeServiceDetails.recipeClass && edgeServiceDetails.recipeClass === 'ITEP Solutions' ?
                                <div className='top-block-right-sidebar-get-started-body-content-list-text-section'>
                                  <div className='top-block-right-sidebar-get-started-body-content-list-text-title-normal'>
                                    Schedule a demonstration
                                  </div>
                                  <p className='top-block-right-sidebar-get-started-body-content-list-text-value'>Contact your Intel representative to schedule a demonstration.</p>
                                </div>
                                :
                                <>
                                  <div className='top-block-right-sidebar-get-started-body-content-list-icon'>
                                    {edgeServiceDetails.licenceType === 'clickToAccept' || (edgeServiceDetails.licenceType === null || edgeServiceDetails.licenceType === "" || edgeServiceDetails.licenceType === undefined) ? <i className="spark-icon spark-icon-download spark-icon-light" style={{ fontFamily: 'spark-icon' }} />
                                      : edgeServiceDetails.licenceType === 'cnda' ? <i className="spark-icon spark-icon-user-circle spark-icon-light" style={{ fontFamily: 'spark-icon' }} /> : null}
                                  </div>
                                  {edgeServiceDetails.licenceType === 'clickToAccept' || (edgeServiceDetails.licenceType === null || edgeServiceDetails.licenceType === "" || edgeServiceDetails.licenceType === undefined) ?
                                    <div className='top-block-right-sidebar-get-started-body-content-list-text-section'>
                                      <Link className='top-block-right-sidebar-get-started-body-content-list-text-title' href={`${edgeServiceDetails.selectorToolRecipeId}`}><p className='top-block-right-sidebar-get-started-body-content-list-text-title'>Download </p></Link>
                                      <p className='top-block-right-sidebar-get-started-body-content-list-text-value'>Install and run on your device.</p>
                                    </div> :
                                    edgeServiceDetails.licenceType === 'cnda' ?
                                      <div className='top-block-right-sidebar-get-started-body-content-list-text-section'>
                                        <Link className='top-block-right-sidebar-get-started-body-content-list-text-title' href={`${edgeServiceDetails.selectorToolRecipeId}`}><p className='top-block-right-sidebar-get-started-body-content-list-text-title'>Sign in</p></Link>
                                        <p className='top-block-right-sidebar-get-started-body-content-list-text-value'>Register or sign in to view available options.</p>
                                      </div> : null}
                                </>}
                          </div>
                    </div>
                  </div>
                </div> : null}
                {showDocumentation ? <div className='top-block-right-sidebar-documentation-section'>
                  <div className='top-block-right-sidebar-documentation-heading'>
                    <div className='top-block-right-sidebar-documentation-heading-content'>
                      <div className='top-block-right-sidebar-documentation-heading-content-title'>
                        <p className='top-block-right-sidebar-documentation-heading-content-title-text'>Documentation</p>
                      </div>
                    </div>
                  </div>
                  <div className='top-block-right-sidebar-documentation-body'>
                    <div className='top-block-right-sidebar-documentation-body-content'>
                      {showUserGuide ? <div className='top-block-right-sidebar-documentation-body-content-list'>
                        <div className='top-block-right-sidebar-documentation-body-content-list-icon-frame'>
                          <i className='top-block-right-sidebar-documentation-body-content-list-icon spark-icon spark-icon-arrow-large-up-right spark-icon-light'></i>
                        </div>
                        <div className='top-block-right-sidebar-documentation-body-content-list-value-frame'>
                          <Link href={edgeServiceDetails.userGuideLink} target="_blank" className='top-block-right-sidebar-documentation-body-content-list-value-text'>User Guide</Link>
                        </div>
                      </div> : null}
                      {showAPIDocLink ? <div className='top-block-right-sidebar-documentation-body-content-list'>
                        <div className='top-block-right-sidebar-documentation-body-content-list-icon-frame'>
                          <i className='top-block-right-sidebar-documentation-body-content-list-icon spark-icon spark-icon-arrow-large-up-right spark-icon-light'></i>
                        </div>
                        <div className='top-block-right-sidebar-documentation-body-content-list-value-frame'>
                          <Link href={edgeServiceDetails.apiDocumentationLink} target="_blank" className='top-block-right-sidebar-documentation-body-content-list-value-text'>API Reference Manual</Link>
                        </div>
                      </div> : null}
                    </div>
                  </div>
                </div> : null}
                {githubLanguages ? <div className='top-block-right-sidebar-details-section'>
                  <div className='top-block-right-sidebar-details-heading'>
                    <div className='top-block-right-sidebar-details-heading-content'>
                      <div className='top-block-right-sidebar-details-heading-content-title'>
                        <p className='top-block-right-sidebar-details-heading-content-title-text'>Details</p>
                      </div>
                    </div>
                  </div>
                  <div className='top-block-right-sidebar-details-body'>
                    <div className='top-block-right-sidebar-details-body-content'>
                      <div className='top-block-right-sidebar-details-body-content-list'>
                        <div className='top-block-right-sidebar-details-body-content-list-text-section'>
                          <p className='top-block-right-sidebar-details-body-content-list-text-title'>Language</p>
                          <ul className='top-block-right-sidebar-details-body-content-list-text-value'>
                            {Object.entries(githubLanguages).map(
                              ([key, val]) => (
                                <li key={key}>
                                  {key}: {val}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> : null}
              </div>
              </Column>
            </Grid>
            </Column>
          </Grid>
        </>
        : <Loading data-theme="dark" data-theme-colors="tiber" />}
    </>

  );
};

export default Overview;