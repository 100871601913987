import axios from 'axios';
import token from '../common/axiosHelper';
const { apigeeUrl, selfUIEnv } = require('../constants/config')

const tokenResponse = await token();
const getEdgeServiceContentsUsingId = async (edgeServiceId) => {
    if(tokenResponse.status === 200) {
        try {
            const response = await axios.get(`${apigeeUrl}/edge-services/${edgeServiceId}?env=${selfUIEnv}&content=true&timestamp=${new Date().getTime()}`, { headers: { 'Authorization': `Bearer ${tokenResponse.data.access_token}`, 'Content-Type': 'application/json'}});
            return response;
        }
        catch(error) {
            return error.response;
        }
        
    }
    else {
        return tokenResponse;
    }
    
};

const getGithubLanguages = async (id) => {
    if(tokenResponse.status === 200) {

        try {
            const response = await axios.get(`${apigeeUrl}/github-statistics?id=${id}&timestamp=${new Date().getTime()}`, { headers: { 'Authorization': `Bearer ${tokenResponse.data.access_token}`, 'Content-Type': 'application/json'}});
            return response;
        }
        catch(error) {
            return error.response;
        }
        
    }
    else {
        return tokenResponse;
    }
    
}

const getEdgeServiceMetadata = async (microserviceType, microserviceNameForUrl) => {
    if(tokenResponse.status === 200) {
        try {
            const response = await axios.get(`${apigeeUrl}/edge-services?microserviceType=${microserviceType}&microserviceNameForUrl=${encodeURIComponent(microserviceNameForUrl)}&env=${selfUIEnv}&timestamp=${new Date().getTime()}`, { headers: { 'Authorization': `Bearer ${tokenResponse.data.access_token}`, 'Content-Type': 'application/json'}});
            return response;
        }
        catch(error) {
            
            return error.response;
        }
        
        
        
    }
    else {
        return tokenResponse
    }
    
};

export const overviewPageService = {
    getEdgeServiceContentsUsingId,
    getEdgeServiceMetadata,
    getGithubLanguages
};