// redux
import { combineReducers } from 'redux';
// reducers
import homePageCatalogReducer from '../../reducers/slice/homePageSlice';
import filterReducer from '../../reducers/slice/filterSlice';


const rootReducer = combineReducers({
    homePageCatalog: homePageCatalogReducer,
    filter: filterReducer,
});

export default rootReducer;
