import React from 'react';
import './loader.css'
export default function Loader() {
    return (
        <div className = "LoaderBackground">
            <div className="loadingio-spinner-rolling-jd6tmu9tmn"><div className="ldio-xxxc6nh3ik">
                <div></div>
            </div></div>
        </div>
    )

}
