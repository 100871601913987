import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { homePageService } from "../../../services/homePageService";
// get all requests
export const getAllItems = createAsyncThunk(
  "catalogListing/getAllItems",
  async (rejectWithValue) => {
    try {
      const response = await homePageService.getCatalogList();
      return response?.data ?? [];
    } catch (err) {
      
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  catalogList: [],
  isLoading: false,
};

const homePageSlice = createSlice({
  name: "homePageCatalog",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllItems.pending, (state, action) => {
        state.isLoading = true;
        // do something
      })
      .addCase(getAllItems.fulfilled, (state, action) => {
        state.catalogList = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllItems.rejected, (state, action) => {
        state.catalogList = [];
        state.isLoading = false;
        /*Toast({
          type: 'error',
          title: 'Failure',
          message: 'Requests Loading Failed !',
        });*/
      });
  },
});

export default homePageSlice.reducer;
