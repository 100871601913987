import { useLocation, useNavigate, useParams, useNavigationType } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    // console.log(
    //   'Inside withRouter',
    //   '\ncomponent',
    //   Component,
    //   '\nprops',
    //   props
    // );
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let action = useNavigationType(); 
    let history = { push: navigate, action };
    return (
      <Component
        {...props}
        location={location}
        history={history}
        navigate={navigate}
        params={params}
        // router={{ location, navigate, params, history }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;