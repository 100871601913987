// import React from 'react';
import './HomePage.scss';
import Header from '../common/Header';
import { HeroBanner } from "../common/HeroBanner";
import EnterpriseAISoftwareBlade from '../EnterpriseAISoftwareBlade';
import LearnMore from '../LearnMore';
import ExploreTile from '../ExploreTile';
import { useNavigate } from 'react-router-dom';
import { Button, Column, Grid, Heading, Section } from '@carbon/react';

const HomePage = () => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/software-catalog');
  };

  const content = <Grid condensed={true} style={{ gap: '16px', alignItems: 'center', padding: 0 }} >
    <Column lg={13} md={5} sm={4}> <Section level={1}><Heading className="hero-banner-text" size="m" color='inverse' >Discover Intel® Tiber™ Edge Platform services and tools to get started with building, optimizing, deploying, and managing the lifecycle of edge AI solutions.</Heading></Section></Column>
    <Column lg={3} md={3} sm={4}>
      <div className='hero-banner-button'>
        <div className='hero-banner-button-content'>
          <Button style={{ justifySelf: 'flex-end' }} kind="secondary" size="lg" className='hero-banner-button-content-text' onClick={handleClick}>Full Catalog &nbsp;<div className='hero-banner-button-icon-section'><i className="spark-icon spark-icon-light spark-icon-arrow-large-right"></i></div></Button>
        </div>
      </div>
    </Column>        </Grid>

  return (

    <>
      <Header />
      <HeroBanner
        variant="primary">
        {content}
      </HeroBanner>
      <EnterpriseAISoftwareBlade />
      {/*<LearnMore />*/}
      <ExploreTile />
    </>

  );
};

export default HomePage;