import React from "react";
import Dialog from "@mui/material/Dialog";
import { withTranslation } from "react-i18next";
import styles from "./header.module.css";
import { connect } from "react-redux";
import { GetUserData, SelectedLanguage } from "../../actions/EIAction";
import LinkImage from "../../resources/images/ic_link.svg";
import CloseIcon from "@mui/icons-material/Close";
class RequestAccess extends React.Component {
  render() {
    const { t } = this.props;
    const selectedLanguage = this.props.EIReducer.selectedLanguage;
    let EITitle = "";
    if (this.props.EIReducer.EITitle) {
      EITitle = this.props.EIReducer.EITitle[selectedLanguage];

      if (EITitle === undefined || EITitle === "") {
        EITitle = this.props.EIReducer.EITitle["en"];
      }
    }
    return (
      <div>
        <Dialog
          open={this.props.openDialog}
          className={styles.RequestAccessDialogBox}
          PaperProps={{
            style: {
              borderRadius: "0px",
              width: "100%",
            },
          }}
        >
          {this.props.gtsBlockerResponse
            ? (<React.Fragment>
                <div className={styles.RequestAccessDialogTitle}>
                  {t("ACCESS_REQUEST_RECEIVED")}
                <span className={styles.RequestAccessDialogCloseIcon}>
                  <CloseIcon
                    className={styles.fontsizeForward}
                    onClick={this.props.closeRequestAccessDialog}
                  />
                </span>
                </div>
                <div className={styles.RequestAccessDialogContent}>
                  {t("REQUEST_ACCESS_DIALOG_THANKING")}&nbsp;
                  <div className={styles.RequestAccessDialogEITitle}>{EITitle}</div>
                  <div className={styles.RequestAccessDialogContentLine2}>
                    {this.props.customMessage}
                  </div>
                </div>
                <div className={styles.RequestAccessDialoSupportTitleDiv}>
                  <div className={styles.RequestAccessDialoSupportTitle}>
                    {t("SUPPORT")}
                  </div>
                  <div className={styles.RequestAccessDialoSupportTitleContent}>
                    <div style={{textTransform: 'capitalize'}}>{t("CONTACT_OUR_SUPPORT_TEAM")}</div>
                    Email Us : <a href="mailto:ircdevops@intel.com" target="_blank">ircdevops@intel.com</a>
                  </div>
                </div>
              </React.Fragment>)
            : (<React.Fragment>
                <div className={styles.RequestAccessDialogTitle}>
                {this.props.CNDAServiceResponse === "success"
                  ? t("ACCESS_REQUEST_RECEIVED")
                  : t("ACCESS_REQUEST_FAILED")}
                <span className={styles.RequestAccessDialogCloseIcon}>
                  <CloseIcon
                    className={styles.fontsizeForward}
                    onClick={this.props.closeRequestAccessDialog}
                  />
                </span>
              </div>
              {this.props.CNDAServiceResponse === "success" ? (
                <div className={styles.RequestAccessDialogContent}>
                  {t("REQUEST_ACCESS_DIALOG_THANKING")}&nbsp;
                  <div className={styles.RequestAccessDialogEITitle}>{EITitle}</div>
                  {t("REQUEST_ACCESS_DIALOG_CONTENT")}
                  <div className={styles.RequestAccessDialogContentLine2}>
                    {t("REQUEST_ACCESS_DIALOG_APPROVAL")}
                  </div>
                </div>
              ) : (
                <div className={styles.RequestAccessDialogContent}>
                  {t("PLEASE_TRY_AGAIN_LATER")}
                </div>
              )}
              <div className={styles.RequestAccessDialoSupportTitleDiv}>
                <div className={styles.RequestAccessDialoSupportTitle}>
                  {t("SUPPORT")}
                </div>
                <div className={styles.RequestAccessDialoSupportTitleContent}>
                  {/* {t("FOR_EDGE_SOFTWARE_HUB_ISSUES")} */}
            <a
                        href={this.props.EIReducer.supportLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.gettingStartedGuide}
                      >
                  <span className={styles.RequestAccessDialogcontactSupportTeam}>
                    {t("CONTACT_OUR_SUPPORT_TEAM")}&nbsp;
                    {t("TO_REPORT_AND_SOLVE_ISSUES")}
                  </span>
                  &nbsp;
                  <img
                    src={LinkImage}
                    className={styles.RequestAccessDialogLinkImage}
                    alt="link"
                  />
            </a>
                </div>
              </div>
            </React.Fragment>)
          }
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, { GetUserData, SelectedLanguage })(
  withTranslation()(RequestAccess)
);
