import "./Card.scss";
// import { Link as RouterLink } from "react-router-dom";
import { Button, Tag, Link } from "@carbon/react"
import iconMicroservice from "../../../assets/cube-detached-left.svg"
import iconRI from "../../../assets/build-cube.svg"
import iconSample from "../../../assets/sample.svg"
const {
    catalogUi: { maxLengthMicroserviceName, maxLengthMicroserviceDescription },
    svgMapping,
} = require("../../../constants/config");

function getTextFromHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.innerText ?? "";
}

const Card = (props) => {
    let cardItem = props.content
    // let showIcon = props.showIcon
    const getName = (name) => {
        return name?.length > maxLengthMicroserviceName
            ? `${name.substring(0, maxLengthMicroserviceName)}...`
            : name;
    };
    const encodeURIComponentModified = (str) => {
        str = str.replace(/%/g, '%25');
        return encodeURIComponent(str);
    };
    const getDescription = (description) => {
        let desc = getTextFromHtml(description);
        return desc?.length > maxLengthMicroserviceDescription
            ? `${desc.substring(0, maxLengthMicroserviceDescription)}...`
            : desc;
    };
    const getIcon = (contentType) => {
        let item = svgMapping.filter(function (el) {
            return el.contentType === contentType
        });
        return (item && item.length > 0 && item[0].icon) ? item[0].icon : "iconMicroservice"
    }
    const getCardIcon = (contentType) => {
        const iconName = getIcon(contentType)
        let iconTag = <></>
        switch (iconName) {
            case "iconMicroservice":
                iconTag = <img src={iconMicroservice} alt="Microservice" />
                break
            case "iconRI":
                iconTag = <img src={iconRI} alt="Reference Implementation" />
                break
            case "iconSample":
                iconTag = <img src={iconSample} alt="Sample Application" />
                break
            default:
                iconTag = <img src={iconMicroservice} alt="Microservice" />
        }
        return iconTag
    }
    return (
            <div className="project-tile">
                {/* <RouterLink to={`/details/?microserviceType=${cardItem.microserviceType}&microserviceNameForUrl=${encodeURIComponentModified(cardItem.microserviceNameForUrl)}`} state={{ edgeServiceId: cardItem.id }}> */}
                    <div className="project-tile-text-content">
                        <div className="project-tile-content">
                            {getCardIcon(cardItem.contentType)}
                            <div className="project-tile-header">
                                <p className="project-tile-heading">{getName(cardItem.displayName)}</p>
                                <div
                                    className="project-tile-tags-wrapper"
                                >
                                    {cardItem.contentType && cardItem.contentType.length > 0 ? (
                                        <Tag size="sm" className="project-tile-tags">
                                            {cardItem.contentType}
                                        </Tag>
                                    ) : null}
                                    {cardItem.industry.map((tag, index) => (
                                        (index === 0 ? (<Tag size="sm" className="project-tile-tags">
                                            {tag}
                                        </Tag>) : null)

                                    ))}
                                    {cardItem.domain.map((tag, index) => (
                                        (index === 0 ? <Tag size="sm" className="project-tile-tags">
                                            {tag}
                                        </Tag> : null)
                                    ))}
                                    {(cardItem.domain.length > 1 || cardItem.industry.length > 1) ?
                                        (<div className="card-tooltip-tags">
                                            <Button className="cds--tag cds--tag--sm cds--layout--size-sm" kind="ghost" autoAlign hasIconOnly tooltipPosition="bottom"
                                                iconDescription={
                                                    <>
                                                        {cardItem.domain.map((tag, index) => (
                                                            (index > 0 ? <Tag size="sm" className="project-tile-tags">
                                                                {tag}
                                                            </Tag> : null)
                                                        ))}
                                                        {cardItem.industry.map((tag, index) => (
                                                            (index > 0 ? (<Tag size="sm" className="project-tile-tags">
                                                                {tag}
                                                            </Tag>) : null)

                                                        ))}
                                                    </>
                                                }
                                                renderIcon={() =>
                                                    <i
                                                        className="spark-icon spark-icon-ellipsis-h spark-icon-solid"
                                                        style={{ fontFamily: 'spark-icon' }}
                                                    />
                                                } />
                                        </div>) :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="project-tile-description">
                                {getDescription(cardItem.microserviceDescription)}
                            </div>
                        </div>
                    </div>
                {/* </RouterLink> */}
                {/* <Link className={showIcon ? "show" : "hide"} href={`/details/?microserviceType=${cardItem.microserviceType}&microserviceNameForUrl=${encodeURIComponentModified(cardItem.microserviceNameForUrl)}`} target="_self">
                    <i className="spark-icon spark-icon-regular spark-icon-arrow-large-right card-goto-icon"></i>
                </Link> */}
                <div className="show">
                <Link className={"card-goto-icon"} href={`/details/?microserviceType=${cardItem.microserviceType}&microserviceNameForUrl=${encodeURIComponentModified(cardItem.microserviceNameForUrl)}`} target="_self">
                    <i className="spark-icon spark-icon-regular spark-icon-arrow-large-right card-goto-icon"></i>
                </Link>
                </div>
                
            </div>
        
    );
};

export default Card;
