// import React from 'react';
// react router
import { useNavigate } from 'react-router-dom';
// material-ui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// images
import PageNotFoundIcon from '../../assets/page_not_found.svg';
// scss
import './errorPage.scss';
import Header from '../common/Header';

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <>
      <Header />
      <div className="page__not__found__container">
        <img src={PageNotFoundIcon} alt="Something went wrong" />
        <Typography className="mc__heading__h2__light mt-32">
          Page not found.
        </Typography>
        <Typography className="mc__text__type__3 mt-8">
          We can't seem to find the page you're looking for. To return to Home,
          click on the button below.
        </Typography>
        {/* <pre className="error__msg">{error.message}</pre> */}
        <Button
          className="mc_btn mc_primary_btn mt-16"
          onClick={handleBackToHome}
        >
          Back To Home
        </Button>
      </div>
    </>
  );
};

export default PageNotFound;