import { ENSIGHT_TYPE, RECIPE_TYPES_NON_DWNLD, RECIPE_TYPE_ITEP } from "./types";
import axios from "axios";
import _ from "lodash";
import config from '../configurator.config';
import configureAxios from './axiosHelper';
// configureAxios(axios);

/* import MockAdapter from 'axios-mock-adapter';
import FETCHDATARES from './fetchdataStubResponse-ITEP-proposedITEPSw'
const mock = new MockAdapter(axios); */
//const serviceBaseURL = "/"; 
//const serviceBaseURL = 'https://recipeconfigurator-quiet-toucan.apps1-bg-int.icloud.intel.com/iot/edgesoftwarehub/download/'
// const serviceBaseURL = 'https://mc-dev-esh-ui.apps1-ir-int.icloud.intel.com/';
const serviceBaseURL = config.serviceURL;

export const UpdateEnsightContent = (recipeName, history) => (dispatch) => {
  configureAxios(axios);
  console.log("UpdateEnsightContent called" )
	let data = {};
	let URL = serviceBaseURL + 'fetchdata/' + recipeName;
  /* const mockedResponse = 
    {...FETCHDATARES};
  mock.onGet(URL).reply(200, mockedResponse); */
	dispatch({
		type: 'ShowLoader',
		payload: true,
	});
	axios
		.get(URL)
		.then((response) => {
			if (response && response.status === 200) {
				if (response.data && response.data.recipes.length) {
					data = response.data;
					let baseURL = window.location.pathname;
					baseURL = baseURL.split('/home');
					if (baseURL.length > 1) {
						data.baseURL = baseURL[1];
					} else {
						data.baseURL = baseURL[0];
					}
					data.serviceBaseURL = serviceBaseURL;
          console.log("UpdateEnsightContent called1 data:", data )
          if (RECIPE_TYPES_NON_DWNLD.includes(data.recipes[0].recipeTypeName.en)) {
            if (data.recipes[0].distributionIds.length) {
              data.recipes[0].ingredients = data.recipes[0].distributionIds;
            } else if (data.recipes[0].recipeTypeName.en === 'ITEP Solutions') {
              data.recipes[0].itepIngredients=[];
              data.recipes[0].itepIngredients.push({
                "id": data.recipes[0].id,
                "ircProductId": data.recipes[0].ircProductId,
                "consumption": {
                  "en": `export token='<jwt>'; curl -k -O -s -H "Authorization: Bearer \${token}" ${serviceBaseURL}download/<package-id>/install.sh; bash install.sh "\${token}"`
                },
              })
            }
					}
					let ingredients = data.recipes[0].ingredients;
					let dependencyList = [];
					ingredients.forEach((ingredient, index) => {
						let dependencies = ingredient.dependencies;
						if (dependencies && dependencies.length) {
							dependencies.forEach((dependency) => {
								let depend = {
									dependerId: ingredient.id,
									dependeeId: dependency,
								};
								dependencyList.push(depend);
							});
						}
            console.log("UpdateEnsightContent called2 data:", data )
						if (RECIPE_TYPES_NON_DWNLD.includes(data.recipes[0].recipeTypeName.en)) {
							let filterContent = data.recipes[0].filterTags;

							let filterOptions = filterContent.map(function (
								filterTag,
								index
							) {
								let arr = {};
								let id = filterTag._id;
								let tags = filterTag.tags;
								tags.forEach((element) => {
									if (ingredient.filterTags.includes(element.id)) {
										arr[id] = element.id;
									}
								});
								return arr;
							});
							filterOptions = filterOptions.filter(
								(value) => Object.keys(value).length !== 0
							);
							ingredients[index].filterOptions = filterOptions;
						}
					});
          console.log("UpdateEnsightContent called3 data:", data )
					data.dependency = dependencyList;
					ingredients = ingredients.map((obj) => ({ ...obj, isChecked: true }));
					var customisedIngedients = _.mapValues(
						_.groupBy(ingredients, 'ingredientType'),
						(clist) =>
							clist.map((ingredients) => _.omit(ingredients, 'ingredientType'))
					);

					data.customisedIngedients = customisedIngedients;
					data.recipeClass = data.recipes[0].recipeTypeName.en;
					data.licenceType = data.recipes[0].licenceType;
					data.otherFiles = data.recipes[0].otherFiles;
					data.hardwareAndSystemRequirementLinks =
						data.recipes[0].hardwareAndSystemRequirementLinks;
					//data.licenceType = "cnda"
					let selectedOS = data.recipes[0].defaultOs;
					let allOSList = data.os;
					let supportedOsIds = data.recipes[0].supportedOsIds;
					let supportedOSList = [];
					let filterContent = data.recipes[0].filterTags;
					let filterTags = [];
					let twmpFilterContent = [];
					let selectedOSName = [];
					if (!data.recipes[0].supportLink) {
						data.recipes[0].supportLink =
							'https://software.intel.com/content/www/us/en/develop/articles/edge-software-hub-support.html';
					}

					if (data.recipes[0].isBannerRequired === undefined) {
						data.recipes[0].isBannerRequired = true;
					}

					selectedOSName = allOSList.filter((OSList, index) => {
						if (OSList.id === selectedOS) {
							return true;
						}
						return false;
					});
					data.selectedOSName = selectedOSName[0]?.label;
					twmpFilterContent = filterContent.filter((filterTag, index) => {
						if (filterTag._id && filterTag.tags.length) {
							let filters = {};
							filters.id = filterTag._id;
							filters.tagIds = [];
							let tags = filterTag.tags;
							tags.forEach((element) => {
								filters.tagIds.push(element.id);
							});
							filterTags.push(filters);
							return true;
						}
						return false;
					});
					filterContent = twmpFilterContent;
					data.filterContent = filterContent;
					allOSList.forEach((element) => {
						supportedOsIds.forEach((id) => {
							if (element.id === id) {
								supportedOSList.push(element);
							}
						});
					});
					data.osList = supportedOSList;
					data.filterTags = filterTags;
					data.isProductKeyRequired = data.recipes[0].productKey;
					let selectedFilterOption = [];
					let selectedFilterOptions = {};
          console.log("UpdateEnsightContent called4 data:", data )
					if (!RECIPE_TYPES_NON_DWNLD.includes(data.recipes[0].recipeTypeName.en)) {
						let filterContent = data.recipes[0].filterTags;
						let filterTags = [];
						let twmpFilterContent = [];
						twmpFilterContent = filterContent.filter((filterTag, index) => {
							if (filterTag._id && filterTag.tags.length) {
								let filters = {};
								filters.id = filterTag._id;
								filters.tagIds = [];
								let tags = filterTag.tags;
								tags.forEach((element) => {
									filters.tagIds.push(element.id);
								});
								filterTags.push(filters);
								return true;
							}
							return false;
						});
						filterContent = twmpFilterContent;
						data.filterContent = filterContent;
						if (filterTags.length) {
							selectedFilterOption = filterTags.map(function (x) {
								return x.tagIds[0];
							});
							selectedFilterOptions = filterTags.map(function (x) {
								let id = x.id;
								return {
									[id]: x.tagIds[0],
								};
							});
						}
					}
					data.selectedFilterOptions = selectedFilterOptions;
					data.selectedFilterOption = selectedFilterOption;
					let query = {};
					let xmlQuery = {};
          data.recipeURL = recipeName;
          console.log("UpdateEnsightContent called5 data:", data )
					if (!RECIPE_TYPES_NON_DWNLD.includes(data.recipes[0].recipeTypeName.en)) {
						query = {
							osIds: selectedOS,
							isRecommended: true,
							hideDisplay: false,
						};
						xmlQuery = {
							osIds: selectedOS,
							isRecommended: true,
							hideInstall: false,
						};
					}
					if (selectedFilterOption.length) {
						query.filterTags = selectedFilterOption;
					}
					let supportedResourcesId = data.recipes[0].resource.concat(
						data.recipes[0].resourceIds
					);
					let supportedResources = [];
					let allResources = data.resources;
					allResources.forEach((resource) => {
						supportedResourcesId.forEach((resourceId) => {
							if (resourceId === resource.id) {
								supportedResources.push(resource);
							}
						});
					});
					data.supportedResources = supportedResources;
					let downloadDetails = [];
					data.selectedIngredients = [];
					if (data.recipes[0] && data.recipes[0].ingredients.length) {
						downloadDetails = data.recipes[0].ingredients;
						downloadDetails.map((obj) => ({ ...obj, isChecked: true }));
						let filteredIngredients = downloadDetails.filter(function (item) {
							for (var key in query) {
								let queryVal = query[key];
								if (key === 'isRecommended' || key === 'hideDisplay') {
									if (item[key] === undefined || item[key] !== query[key])
										return false;
								} else if (Array.isArray(queryVal)) {
									let flag = true;
									if (queryVal.length === 0) {
										flag = false;
									}
									for (var k in queryVal) {
										if (
											item[key].indexOf(queryVal[k].toString()) === -1 &&
											item[key] !== undefined
										) {
											flag = false;
											return false;
										}
									}
									return flag;
								} else {
									if (
										item[key] === undefined ||
										item[key].indexOf(query[key]) < 0
									)
										return false;
								}
							}
							if (
								RECIPE_TYPES_NON_DWNLD.includes(data.recipes[0].recipeTypeName.en) &&
								Object.keys(query).length === 0
							) {
								return false;
							}
							return true;
						});
						let filteredIngredientsXML = downloadDetails.filter(function (
							item
						) {
							for (var key in xmlQuery) {
								let queryVal = xmlQuery[key];
								if (key === 'isRecommended' || key === 'hideInstall') {
									if (item[key] === undefined || item[key] !== xmlQuery[key])
										return false;
								} else if (Array.isArray(queryVal)) {
									let flag = true;
									if (queryVal.length === 0) {
										flag = false;
									}
									for (var k in queryVal) {
										if (
											item[key].indexOf(queryVal[k].toString()) === -1 &&
											item[key] !== undefined
										) {
											flag = false;
											return false;
										}
									}
									return flag;
								} else {
									if (
										item[key] === undefined ||
										item[key].indexOf(query[key]) < 0
									)
										return false;
								}
							}
							if (
								RECIPE_TYPES_NON_DWNLD.includes(data.recipes[0].recipeTypeName.en) &&
								Object.keys(query).length === 0
							) {
								return false;
							}
							return true;
						});
						/* filteredIngredients = filteredIngredients.filter((ingredients) => {
              if (ingredients.hideDisplay === false) {
                return _.omit(ingredients, "ingredientType")
              } else {
                return false;
              }
        
            }); */
						data.recipeURL = recipeName;
						data.selectedIngredients = filteredIngredients;
						data.selectedIngredientsXML = filteredIngredientsXML;
					}
					let EITitle = '';
					if (
						data.recipes[0].displayName &&
						data.recipes[0].displayName.en &&
						data.recipes[0].displayName.en !== ''
					) {
						EITitle = data.recipes[0].displayName;
					} else {
						EITitle = data.recipes[0].name;
					}
					data.EITitle = EITitle;
          console.log("UpdateEnsightContent called6 data:", data )
					if (data.licenceType.toLowerCase() === 'cnda') {
						data.isCNDAVerifyEntitlementCompleted = false;
						let dataForVerifyEntitlement = data.recipes[0].ircProductId;
						dispatch(
							VerifyEntitlement(dataForVerifyEntitlement, 'checkCNDALicense')
						);
					}
          console.log("UpdateEnsightContent called7 data:", data )
					dispatch({
						type: ENSIGHT_TYPE,
						payload: data,
					});
				} else {
					dispatch({
						type: 'isNoRecipe',
						payload: true,
					});
				}
			} else {
				dispatch({
					type: 'isNoRecipe',
					payload: true,
				});
				history.push('/package/serviceunavailable');
			}
			//}
		})
		.catch((error) => {
			const { response } = error;
			console.log(error);
			if (response && response.status === 404) {
				history.push('/package/404');
			} else {
				dispatch({
					type: 'isNoRecipe',
					payload: true,
				});
				history.push('/package/serviceunavailable');
				//navigate("/serviceunavailable");
				//return Promise.reject(response);
			}
		})
		.finally(function () {
			if (data && data.licenceType?.toLowerCase() !== 'cnda')
				dispatch({
					type: 'HideLoader',
					payload: true,
				});
		});
};

export const UpdateRecipeVersion = (data) => (dispatch) => {
  dispatch({
    type: "updateRecipeVersion",
    payload: data,
  });
};
export const UpdateUseCaseSelection = (data) => (dispatch) => {
  dispatch({
    type: "UpdateUseCaseSelection",
    payload: data,
  });
};
export const UpdateRecipeClass = (data) => (dispatch) => {
  console.log('EIActions UpdateRecipeClass called')
  dispatch({
    type: "UpdateRecipeClass",
    payload: data,
  });
};
export const UpdateOptInOption = (data) => (dispatch) => {
  dispatch({
    type: "UpdateOptInOption",
    payload: data,
  });
};
export const updateCNDAService = () => (dispatch) => {
  dispatch({
    type: "UpdateCNDAServiceResponse",
    payload: false,
  });
}
export const HandleLoader = (isShowLoader) => (dispatch) => {

  if (isShowLoader) {
    dispatch({
      type: "ShowLoader",
      payload: true,
    });
  } else {
    dispatch({
      type: "HideLoader",
      payload: true,
    });
  }
};

export const UpdateDisableRecipeList = (data) => (dispatch) => {
  dispatch({
    type: "UpdateDisableRecipeList",
    payload: data,
  });
};

export const UpdateOSSelection = (selectedOS, selectedOSText) => (dispatch) => {
  let data = {
    selectedOS,
    selectedOSText,
  };
  dispatch({
    type: "UpdateOSSelection",
    payload: data,
  });
};

export const UpdateServiceRequest = () => (dispatch) => {
  dispatch({
    type: "UpdateServiceRequest",
    payload: true,
  });
};

export const UpdateSelectedIngredients = (data) => (dispatch) => {
  dispatch({
    type: "UpdateSelectedIngredients",
    payload: data,
  });
};

export const UpdateUserSelections = (data) => {
  return async (dispatch) => {
    if (data.isUpdateRecipeVersion) {
      dispatch({
        type: "updateRecipeVersion",
        payload: data.updateRecipe,
      });
    }

    if (data.isUpdateFilterSelection) {
      dispatch({
        type: "updateFilterOption",
        payload: data.updateFilterOptions,
      });
    }
   await dispatch({
      type: "UpdateUserSelections",
      payload: data,
    });
    return true;
  }
}

export const downloadRecipe = (data, recipeClass, targettedURL) => (
  dispatch
) => {
  configureAxios(axios);
  console.log("downloadRecipe called" )
  let URL = serviceBaseURL + "download";
  // const mockedResponse = {
  //   data: 'abc',
  // };
  // mock.onPost(URL).reply(200, mockedResponse);
  let packageName = "edge_insights.zip";
  if (data.recipeName) {
    packageName = (data.recipeName).split(' ').join('_') + ".zip";
  }
  if (data.zipLabel !== null && data.zipLabel !== undefined && data.zipLabel.trim() !== "") {
    packageName = data.zipLabel + ".zip"
  }
  //const cancelTokenSource = CancelToken.source();

  axios({
    method: "post",
    url: URL,
    data: data,
    responseType: "blob",
    //cancelToken: cancelTokenSource.token,
    onDownloadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch({
        type: "UpdatePackagingPercentage",
        payload: percentCompleted,
      });
    },
  })
    .then(({ data }) => {
      let currentURL = window.location.href;
      if (currentURL.indexOf(targettedURL) > -1) {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", packageName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      // if (data.status === 500) {
      //     dispatch({
      //       type: "UpdateErrorMessage",
      //       payload: data,
      //     });

      // }
    })
    .catch((response) => {
      let data = "Please try again later!!";
      dispatch({
        type: "UpdateErrorMessage",
        payload: data,
      });
      return Promise.reject(response);
    });
  //cancelTokenSource.cancel();
};

export const requestcnda = (data) => {
  console.log("requestcnda called" )
  configureAxios(axios);
  return async (dispatch) => {
    dispatch({
      type: "ShowLoader",
      payload: true,
    });
    let URL = serviceBaseURL + "requestcnda";
    function onSuccess(response) {
      if (response.status === 200) {
        dispatch({
          type: "UpdateCNDAServiceResponse",
          payload: "success",
        });
      }
      dispatch({
        type: "HideLoader",
        payload: true,
      });
      return "success";
    }
    function onError(error) {
      dispatch({
        type: "HideLoader",
        payload: true,
      });
      dispatch({
        type: "UpdateCNDAServiceResponse",
        payload: "failed",
      });
      return "failed";
    }
    try {
      let response = await axios({
        method: "post",
        url: URL,
        data: data,
      });
      return onSuccess(response);
    } catch (response) {
      return onError(response);
    }
  };
};


export const GetUserData = () => (dispatch) => {
  console.log("GetUserData called" )
  configureAxios(axios);
  let URL = serviceBaseURL + "user/data";
  /* const mockedResponse = {
    data: {"username":"Bhardwaj , Nakul","country":"IN"},
  };
  mock.onGet(URL).reply(200, mockedResponse); */
  let data = {};
  axios({
    method: "get",
    url: URL,
  })
    .then((response) => {
      console.log("GetUserData", response )
      if (response) {
        data = response.data;
        //data = {"username":"Veerarethinam , Vinothkumar"};
        dispatch({
          type: "UpdateUserDetails",
          payload: data,
        });
      }
    }
    )
    .catch((response) => {

      return Promise.reject(response);
    });
};
export const GetLicenseData = (recipeId) => {
  configureAxios(axios);
  console.log("GetLicenseData called" )
  /* let URL = serviceBaseURL + "fetchdata/licenseagreement/" + recipeId;
  const mockedResponse={
    "id": "668502f6a04d63b3d1dcc3c5",
    "name": {
        "en": "ESC_caas-package0702"
    },
    "label": "ESC_caas-package0702",
    "displayName": {},
    "licenseAgreement": "<p></p>"
  }
  mock.onGet(URL).reply(200, mockedResponse); */
  return async (dispatch) => {
    let URL = serviceBaseURL + "fetchdata/licenseagreement/" + recipeId;
  let data = {};
  dispatch({
    type: "ShowLoader",
    payload: true,
  });
    function onSuccess(response) {
      dispatch({
          type: "GetLicenseData",
          payload: response.data,
        });
      dispatch({
        type: "HideLoader",
        payload: true,
      });
      return "success";
    }
    function onError(error) {
      dispatch({
        type: "HideLoader",
        payload: true,
      });
      data = error.data;
        dispatch({
          type: "UpdateErrorMessage",
          payload: data,
        });
      return "failed";
    }
    try {
      let response = await axios({
        method: "get",
        url: URL,
      });
      return onSuccess(response);
    } catch (response) {
      return onError(response);
    }
  };
};

export const checkEntitlementAndGenerateUniqueKeyNoRedirect = (data) => async (dispatch) =>{
  console.log("checkEntitlementAndGenerateUniqueKeyNoRedirect called" )
  configureAxios(axios);
  let URL = serviceBaseURL + "irc/checkEntitlementAndGenerateUniqueKey";
  /* const mockedResponse={"status":202,"productKey":null,"message":"Redirecting to License Agreement"}
  mock.onPost(URL).reply(202, mockedResponse); */
  dispatch({
    type: "ShowLoader",
    payload: true,
  });
  try{
  var res = await axios({
      method: "post",
      url: URL,
      data: data,
    });
    if (res.status) {
      dispatch({
        type: "HideLoader",
        payload: true,
      });
    }
    if (res.status == 200){
      dispatch({
        type: 'UpdateOdLicenseStatus',
        payload: {[data.recipeId]:true}
      })
    }

  }catch(error){
    let data = "Please try again later!!";
    dispatch({
      type: "HideLoader",
      payload: true,
    });
    dispatch({
      type: "UpdateErrorMessage",
      payload: data,
    });
  }
}

export const checkEntitlementAndGenerateUniqueKey = (data, downloadType, history) => async (
  dispatch
) => {
  console.log("checkEntitlementAndGenerateUniqueKey called" )
  configureAxios(axios);
  let URL = serviceBaseURL + "irc/checkEntitlementAndGenerateUniqueKey";
  /* const mockedResponse={
      "status": 200,
      "productKey": null,
      "uniqueId": "66e11f68f4ed30421fa7f543",
      "message": "User Permitted to download"
  }
  mock.onPost(URL).reply(200, mockedResponse); */
  const isUserOptIn = data.isUserOptIn;
  let isvalid = false;
  dispatch({
    type: "UpdateOptInOption",
    payload: isUserOptIn,
  });
  dispatch({
    type: "UpdateDownloadType",
    payload: downloadType,
  });
  dispatch({
    type: "ShowLoader",
    payload: true,
  });
  try {
    var res = await axios({
      method: "post",
      url: URL,
      data: data,
    });
    if (res.status) {
      dispatch({
        type: "HideLoader",
        payload: true,
      });
    }
    console.log('checkEntitlementAndGenerateUniqueKey called here 1')
    switch (res.status) {
      case 200:
        let recipeType = data.recipeURL;
        console.log('checkEntitlementAndGenerateUniqueKey called case 200 data.recipeClass', data.recipeClass)
        if(RECIPE_TYPES_NON_DWNLD.includes(data.recipeClass)){
          dispatch({
            type: 'UpdateOdLicenseStatus',
            payload: {[data.recipeId]:true}
          })
          dispatch({
            type: 'NavigatedFromLicenseAgreementPage',
            payload: true
          })
          console.log('checkEntitlementAndGenerateUniqueKey called history.push(data.baseURL))', data.baseURL)
          if(RECIPE_TYPE_ITEP.includes(data.recipeClass)){
            // dispatch({
            //   type: 'NavigatedFromDownloadItepPage',
            //   payload: true
            // })
            setTimeout(()=>history.push(data.baseURL + '/download'))
          } else {
            setTimeout(()=>history.push(data.baseURL))
          }
        }
        else{
          data = res.data;
          data.downloadType = downloadType;
          dispatch({
            type: "AddProductID",
            payload: data,
          });
          console.log('checkEntitlementAndGenerateUniqueKey called history.push("/package/downloadrecipe/" + recipeType);)', "/package/downloadrecipe/" + recipeType)
          history.push("/package/downloadrecipe/" + recipeType);
        }
        break;
      case 500:
        data = res.data;
        if (isvalid === true) {
          dispatch({
            type: "UpdateErrorMessage",
            payload: true,
          });

        }
        break;

      default:
        console.log('checkEntitlementAndGenerateUniqueKey called default')
        history.push("/package/licenseagreement/" + data.recipeURL);
        break;
    }
  } catch (e) {
    let data = "Please try again later!!";
    dispatch({
      type: "HideLoader",
      payload: true,
    });
    dispatch({
      type: "UpdateErrorMessage",
      payload: data,
    });
  }
};

export const VerifyEntitlement = (data, downloadType, history) => async (
  dispatch
) => {
  console.log("VerifyEntitlement called data", data, "downloadType", downloadType, "history", history);
  configureAxios(axios);
  let URL = serviceBaseURL + "irc/verifyEntitlement/" + data;
  let isvalid = false;
  dispatch({
    type: "ShowLoader",
    payload: true,
  });
  try {
    var res = await axios({
      method: "get",
      url: URL,
      validateStatus: false
    });
    dispatch({
      type: "HideLoader",
      payload: true,
    });
    let data = {}
    switch (res.status) {
      case 200:
        data = {
          isCNDALicenseApproved: true,
          isCNDArequestRaised: true,
        }
        dispatch({
          type: "UpdateCNDAStaus",
          payload: data,
        });

        break;
      case 202:
        data = {
          isCNDALicenseApproved: false,
          isCNDArequestRaised: true
        }
        dispatch({
          type: "UpdateCNDAStaus",
          payload: data,
        });
        break;
      case 404:
        data = {
          isCNDALicenseApproved: false,
          isCNDArequestRaised: false
        }
        dispatch({
          type: "UpdateCNDAStaus",
          payload: data,
        });
        break;
      case 500:
        data = res.data;
        if (isvalid === true) {
          dispatch({
            type: "UpdateErrorMessage",
            payload: true,
          });

        }
        break;

      default:
        if (downloadType !== "checkCNDALicense") {
          history.push("/package/licenseagreement/" + data.recipeURL);
        } else {
          data = {
            isCNDALicenseApproved: false,
            isCNDArequestRaised: false,
          }
          dispatch({
            type: "UpdateCNDAStaus",
            payload: data,
          });
        }
        break;
    }
  } catch (e) {
    console.log(e);
    let data = "Please try again later!!";
    dispatch({
      type: "HideLoader",
      payload: true,
    });
    dispatch({
      type: "UpdateErrorMessage",
      payload: data,
    });
  }
};

export const RegisterProduct = (data, targettedURL, history) => {
  configureAxios(axios);
  return (dispatch) => {
  console.log("RegisterProduct called" )
  let URL = serviceBaseURL + "irc/registerProduct";
  /* const mockedResponse={
    "status": 200,
    "productKey": null,
    "uniqueId": "66b482dfdb8aabb071b77d03",
    "message": "User Permitted to download"
  }
  mock.onPost(URL).reply(200, mockedResponse); */
  //let data = {};
  dispatch({
    type: "ShowLoader",
    payload: true,
  });
    return new Promise((resolve, reject) =>{
      axios({
        method: "post",
        url: URL,
        data: data,
      })
      .then((response) => {
        let currentURL = window.location.href;
        if (currentURL.indexOf(targettedURL) > -1) {
          if (response) {
            if (response.status === 200) {
              let recipeType = data.recipeURL;
              let recipeClass = data.recipeClass;
              let baseURL = data.baseURL
              let recipeId = data.recipeId
              data = response.data;
              //data = {"username":"Veerarethinam , Vinothkumar"};
              dispatch({
                type: "RegisterProduct",
                payload: data,
              });
              if(RECIPE_TYPES_NON_DWNLD.includes(recipeClass)){
                dispatch({
                  type: 'UpdateOdLicenseStatus',
                  payload: {[recipeId]:true}
                })
                dispatch({
                  type: 'NavigatedFromLicenseAgreementPage',
                  payload: true
                })
                setTimeout(function() {
                  if(RECIPE_TYPE_ITEP.includes(recipeClass)){
                    history.push(baseURL + '/download')
                  } else {
                    history.push(baseURL)
                  }
                })
              }
              else{
                setTimeout(function () {
                  history.push("/package/downloadrecipe/" + recipeType);
                  //navigate("/downloadrecipe");
                });
              }
            }
          }
          if(response.status === 400){
            // show notification for ERR_BAD_REQUEST
            data = response.data;
            dispatch({
              type: "HideLoader",
              payload: true,
            });
            dispatch({
              type: "UpdateErrorMessage",
              payload: data.message,
            });
          }
          if (response.status === 500) {
            data = response.data;
            dispatch({
              type: "HideLoader",
              payload: true,
            });
            dispatch({
              type: "UpdateErrorMessage",
              payload: data,
            });

          }

        }
      }
      )
      .catch((error) => {
        console.log(`error occurred with status ${error.response?.status} and body ${error.response?.data}`)
        if(error.response && (error.status == 400 || error.response?.status === 400)){
          dispatch({
            type: "HideLoader",
            payload: true,
          });
          dispatch({
            type: "UpdateErrorMessage",
            payload: error.response.data.message,
          });
          if(error.response.data?.errorCode == 'GTS_ERROR'){
            dispatch({
              type: 'UpdateGtsBlockerResponse',
              payload: true
            })
            dispatch({
              type: "UpdateGtsBlockerMsg",
              payload: error.response.data?.message
            })
          }
          reject ({
            "errorCode": error.response.data?.errorCode,
            "message": error.response.data?.message,
            "status": error.status || error.response.status
          })
        }
        else{
          let data = "Please try again later!!";
          dispatch({
            type: "UpdateErrorMessage",
            payload: data,
          });
          reject (error);
        }
      }
      );
  })
  }
};

export const UpdateGtsBlockerResponse = (res) =>(dispatch)=>{
  dispatch({
    type: "UpdateGtsBlockerResponse",
    payload: res
  })
}
export const updateOnlineDistroLicenseAcceptedStatus = (status) =>(dispatch) =>{
  dispatch({
    type: "UpdateOdLicenseStatus",
    payload: status
  })
}

export const ClearOnlineDistroLicenseAcceptedStatus = () => (dispatch) =>{
  dispatch({
    type: "ClearOdLicenseStatus",
    payload: {}
  })
}

export const UpdateNavigatedFromLicenseAgreementPage = (status) =>(dispatch) => {
  console.log('EIActions UpdateNavigatedFromLicenseAgreementPage called')
  dispatch({
    type: "NavigatedFromLicenseAgreementPage",
    payload: status
  })
}

export const ClearRecipeDetails = () => (dispatch) => {
  console.log('EIActions ClearRecipeDetails called')
  dispatch({
    type: "ShowLoader",
    payload: true,
  });
  dispatch({
    type: "ClearRecipeDetails",
    payload: true,
  });
};

export const UpdateDownloadType = (downloadType) => (dispatch) => {
  dispatch({
    type: "UpdateDownloadType",
    payload: downloadType
  })
}

export const updateFilterOption = (data) => (dispatch) => {
  dispatch({
    type: "updateFilterOption",
    payload: data,
  });
};

export const SelectedLanguage = (language) => (dispatch) => {
  dispatch({
    type: "SelectedLanguage",
    payload: language,
  });
};

export const RemoveErrorMessage = () => (dispatch) => {
  dispatch({
    type: "RemoveErrorMessage",
    payload: false,
  });
};

export const Logout = () => (dispatch) => {
  console.log("Logout called" )
  configureAxios(axios);
  let URL = serviceBaseURL + "logout";
  axios({
    method: "get",
    url: URL,
  })
    .then((response) => { }
    )
    .catch((response) => {
      return Promise.reject(response);
    });
};

// export const UpdateNavigatedFromDownloadItep = (status) => (dispatch) => {
//   console.log('UpdateVisitedDownloadItep called')
//   dispatch({
//     type: 'NavigatedFromDownloadItepPage',
//     payload:status
//   })
// }
