// import React from "react"

export const Divider = ({
  useSpacing = false,
  spacing = "04",
  borderHeight = "2px",
  borderColor = "subtle",
  style,
  ...rest
}) => {
  return (
    <div
      style={{
        marginBlock: useSpacing
          ? `var(--cds-spark-theme-spacing-${spacing})`
          : "",
        blockSize: borderHeight,
        backgroundColor: `var(--cds-spark-theme-border-${borderColor}-01)`,
        ...style
      }}
      {...rest}
    />
  )
}
