import { useState, useEffect } from 'react';
import './EnterpriseAISoftwareBlade.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, Loading, Grid, Column } from '@carbon/react';
import { useDispatch, useSelector } from "react-redux";
import { getAllItems } from "../../redux/reducers/slice/homePageSlice";
import Card from "../common/Card"
import { useNavigate } from 'react-router-dom';
import {
    
    filterCatalogList,
    removeAllFilters
    
} from "../../redux/reducers/slice/filterSlice";

const EnterpriseAISoftwareBlade = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { catalogList, isLoading } = useSelector(
        (state) => state.homePageCatalog
    );

    const [filteredList, setFilteredList] = useState(catalogList);
    const [sliderSettings, setSliderSettings] = useState({});
    const { filteredCatalogList } = useSelector((state) => state.filter);
    const isMobile = window.innerWidth <= 672;
    const isTablet = window.innerWidth > 672 && window.innerWidth <= 1024;

    const CustomRightArrow = (props) => {
        const { onClick } = props;
        // onMove means if dragging or swiping in progress.

        return (
            <>
                <i onClick={() => onClick()} className="spark-icon spark-icon-light spark-icon-chevron-right slide-arrow next-arrow"></i>
            </>
        )

    };
    const CustomLeftArrow = (props) => {
        const { onClick } = props;
        // onMove means if dragging or swiping in progress.
        return (
            <>
                <i onClick={() => onClick()} className="spark-icon spark-icon-light spark-icon-chevron-left slide-arrow prev-arrow"></i>
            </>
        )

    };

    const groupByName = (array) => {
		let groupedArray = [];
		array = JSON.parse(JSON.stringify(array));
		array.forEach((item) => {
			const collection = groupedArray.find((rec) => rec.microserviceNameForUrl === item.microserviceNameForUrl && rec.microserviceType === item.microserviceType);
			if (collection) {
				collection.allData.push(JSON.parse(JSON.stringify(item)));
			} else {
				item.allData = [JSON.parse(JSON.stringify(item))];
				groupedArray.push(item);
			}
		});
		return groupedArray;
	};

    const handleViewAllClick = () => {
        // navigate(`/software-catalog?topic=Intel® Tiber™ AI Suites`);
        navigate(`/software-catalog`);
    };

    useEffect(() => {
        dispatch(getAllItems());
       
    }, []);

    useEffect(() => {
        if (catalogList) {
            // const filteredData = catalogList.filter((item) => item.domain.includes('Intel® Tiber™ AI Suites'));
            // let groupedList = groupByName(filteredData)
            let groupedList = groupByName(catalogList);
            setFilteredList(groupedList);
            dispatch(filterCatalogList(groupedList));
        }
    }, [catalogList]);

    useEffect(() => {

        if(isLoading) {
            dispatch(removeAllFilters());
        }
        // const filteredData = filteredCatalogList.filter((item) => item.domain.includes('Intel® Tiber™ AI Suites'));
        // let groupedList = groupByName(filteredData)
        let groupedList = groupByName(filteredCatalogList)
        setFilteredList(groupedList);
       
            
        
    }, [filteredCatalogList, catalogList]);

    useEffect(() => {
        const handleResize = () => {
           
            const settings = {
              dots: false,
              infinite: filteredList.length > 4,
              speed: 500,
              slidesToShow: 4,
              slidesToScroll: filteredList.length > 4 ? 4: 0,
              arrows: filteredList.length > 4 && !isMobile && !isTablet,
              prevArrow: <CustomLeftArrow />,
              nextArrow: <CustomRightArrow />,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 0,
                    slidesToScroll: 0,
                    arrows: false,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 0,
                    arrows: false,
                  }
                }
              ]
            };
            setSliderSettings(settings);
          };
          handleResize();
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
    }, [filteredList])

    return (
        <>
            <div className="enterprise-ai-software-blade">
                <div className="carousel-section">
                    <div className="enterprise-ai-software-blade-title-section">
                        {/* <p className="enterprise-ai-software-blade-title-content">Intel® Tiber™ AI Suites</p> */}
                        <p className="enterprise-ai-software-blade-title-content">Featured</p> 
                        <Link tabIndex="0" onClick={handleViewAllClick} className="enterprise-ai-software-blade-title-section-link">
                            <p className="enterprise-ai-software-blade-title-section-link-text">View all </p><i className="spark-icon spark-icon-light spark-icon-arrow-large-right enterprise-ai-software-blade-title-section-link-icon"></i>

                        </Link>
                    </div>
                    { /* <div className="enterprise-ai-software-blade-description-section">
                        <p className="enterprise-ai-software-blade-description-text">Choose from a selection of Intel® Tiber™ AI Suites enabled edge solutions optimized for verticals and use cases.</p>
                    </div> */ }
                    <div className="project-grid">

                        {!isLoading ?
                            window.innerWidth > 1024 ? <>
                            <Slider className='project-grid' {...sliderSettings}>
                            
                                {filteredList.slice(0, 8).map((item, index) => (
                                    <>
                                        
                                       
                                        <Card content={item} showIcon={true} />
                                        
                                        
                                    </>
                                ))}
                              
                            </Slider></>: <Grid condensed={true} style={{ gap: '16px', padding: 0 }}> {filteredList.slice(0, 4).map((item, index) => (
                                <>
                                <Column lg={4} md={4} sm={4}>
                                    
                                        
                                            
                                            <Card content={item} showIcon={true} />
                                           
                                        
                                    
                                    
                                    </Column>
                                </>
                            ))}</Grid>
                            : <Loading data-theme="dark" data-theme-colors="tiber" />}

                    </div>
                </div>
            </div>

        </>


    );
};

export default EnterpriseAISoftwareBlade;