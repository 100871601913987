import { createSlice } from '@reduxjs/toolkit';
const {
  filters: { industryFilters, domainFilters, contentTypeFilters },
} = require('../../../constants/config');
const initialState = {
  filteredCatalogList: [],
  filter: {
    industryFilter: [],
    domainFilter: [],
    contentTypeFilter: [],
    nameFilter: '',
    sortingOrder: 1,
    filterArray: [],
    industryFiltersArray: industryFilters,
    industryFiltersArrayWithCount: [],
    domainFiltersArrayWithCount: [],
    contentTypeFiltersArrayWithCount: [],
    domainFiltersArray: domainFilters,
    contentTypeFiltersArray: contentTypeFilters,
    currentPageNumber: 1,
  }
};
export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    AddToIndustryFilter: (state, action) => {
      // if (!state.filter.industryFilter.includes(
      //     action.payload
      //   )
      // )
      //   state.filter.industryFilter = [
      //     ...state.filter.industryFilter,
      //     action.payload,
      //   ];
      state.filter.industryFilter = [action.payload];
    },
    AddToDomainFilter: (state, action) => {
      // if (!state.filter.domainFilter.includes(action.payload)
      // )
      //   state.filter.domainFilter = [
      //     ...state.filter.domainFilter,
      //     action.payload,
      //   ];
      state.filter.domainFilter = [action.payload];
    },
    resetDomainFilter: (state, action) => {
      state.filter.domainFilter = []
    },
    removeFromIndustryFilter: (state, action) => {
      state.filter.industryFilter = state.filter.industryFilter.filter((tag) => {
        return !(tag === action.payload);
      });
    },
    removeFromDomainFilter: (state, action) => {
      state.filter.domainFilter = state.filter.domainFilter.filter((tag) => {
        return !(tag === action.payload);
      });
    },
    AddToContentTypeFilter: (state, action) => {
      if (
        !state.filter.contentTypeFilter.includes(
          action.payload
        )
      )
        state.filter.contentTypeFilter = [
          ...state.filter.contentTypeFilter,
          action.payload,
        ];
    },
    removeFromContentTypeFilter: (state, action) => {
      state.filter.contentTypeFilter = state.filter.contentTypeFilter.filter((tag) => {
        return !(tag === action.payload);
      });
    },
    removeAllFilters: (state, action) => {
      state.filter.domainFilter = [];
      state.filter.industryFilter = [];
      state.filter.contentTypeFilter = [];

    },
    updateNameFilter: (state, action) => {
      state.filter.nameFilter = action.payload;
    },
    AddToFilterArray: (state, action) => {
      if (
        !state.filter.filterArray.includes(action.payload)
      )
        state.filter.filterArray = [
          ...state.filter.filterArray,
          action.payload,
        ];
    },
    removeFromFilterArray: (state, action) => {
      state.filter.filterArray = state.stateValues[
        state.tabValue
      ].filterArray.filter((tag) => {
        return !(tag === action.payload);
      });
    },
    AddToSortArray: (state, action) => {
      state.filter.sortingOrder = action.payload;
    },
    removeFromSortArray: (state, action) => { },
    changePageNumber: (state, action) => {
      state.filter.currentPageNumber = action.payload;
    },
    updateSideBarFiltersArray: (state, action) => {
      const catalogList = action.payload[0];
      const actualList = action.payload[1];
      let industryFiltersArrayWithCount = [];
      let domainFiltersArrayWithCount = [];
      let contentTypeFiltersArrayWithCount = [];
      state.filter.industryFiltersArray = industryFilters.filter(
        (element) => {
          const itemLength = catalogList.filter((jsonItem) => {
            if (jsonItem.industry.includes(element))
              return true
            return false
          }).length;
          if (itemLength > 0) {
            let tempObj = {
              name: element,
              count: itemLength
            }
            industryFiltersArrayWithCount.push(tempObj)
            return true
          }
          return false
        }
      );
      state.filter.industryFiltersArrayWithCount = industryFiltersArrayWithCount;

      state.filter.domainFiltersArray = domainFilters.filter(
        (element) => {
          const itemLength = catalogList.filter((jsonItem) => {
            if (jsonItem.domain.includes(element))
              return true
            return false
          }).length;
          if (itemLength > 0) {
            let tempObj = {
              name: element,
              count: itemLength
            }
            domainFiltersArrayWithCount.push(tempObj)
            return true
          }
          return false
        }
      );
      state.filter.domainFiltersArrayWithCount = domainFiltersArrayWithCount;

      state.filter.contentTypeFiltersArray = contentTypeFilters.filter(
        (element) => {
          let contentTypeList = actualList;
          if(state.filter.domainFilter.length > 0 || state.filter.industryFilter.length > 0 || state.filter.nameFilter.length > 0) {
            contentTypeList = catalogList
          }
          else {
            contentTypeList = actualList;
          }
          const itemLength = contentTypeList.filter((jsonItem) => {
            if (jsonItem.contentType === element)
              return true
            return false
          }).length;
          if (itemLength > 0) {
            let tempObj = {
              name: element,
              count: itemLength
            }
            contentTypeFiltersArrayWithCount.push(tempObj)
            return true
          }
          //("contentTypeFiltersArrayWithCount", contentTypeFiltersArrayWithCount)
          return false
        }
      );
      state.filter.contentTypeFiltersArrayWithCount = contentTypeFiltersArrayWithCount;
    },
    filterCatalogList: (state, action) => {
      let catalogList = action.payload;
      let catalogListIndustry = [];
      if (state.filter.industryFilter.length > 0) {
        catalogListIndustry = catalogList.filter((element) => {
          return state.filter.industryFilter.some((itemStoreArray) =>
            element.industry.includes(itemStoreArray)
          );
        });
      }
      let catalogListDomain = [];
      if (state.filter.domainFilter.length > 0) {
        catalogListDomain = catalogList.filter((element) => {
          return state.filter.domainFilter.some((itemStoreArray) =>
            element.domain.includes(itemStoreArray)
          );
        });
      }
      let catalogListContentType = [];
      if (state.filter.contentTypeFilter.length > 0) {
        catalogListContentType = catalogList.filter((element) => {
          return state.filter.contentTypeFilter.some((itemStoreArray) =>
            element.contentType === itemStoreArray
          );
        });
      }
      function createArrayWithNoDuplicates(array, field) {
        const arrayWithoutDuplicates = array.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t[field] === value[field])
        );
        return arrayWithoutDuplicates;
      }
      if (state.filter.industryFilter.length > 0 && state.filter.domainFilter.length > 0 && state.filter.contentTypeFilter.length > 0) {
        const catalogListWithoutDuplicates = createArrayWithNoDuplicates(
          [...catalogListIndustry, ...catalogListDomain, ...catalogListContentType],
          'id'
        );
        catalogList = catalogListWithoutDuplicates.filter((element) => {
          return (
              state.filter.domainFilter.some((itemStoreArray) =>
                element.domain.includes(itemStoreArray)
              ) &&
              state.filter.industryFilter.some((itemStoreArray) =>
                element.industry.includes(itemStoreArray)
              ) &&
            state.filter.contentTypeFilter.some((itemStoreArray) =>
              element.contentType.includes(itemStoreArray)
              )
          );
        });
      } else if (state.filter.industryFilter.length > 0 && state.filter.domainFilter.length > 0) {
        const catalogListWithoutDuplicates = createArrayWithNoDuplicates(
          [...catalogListIndustry, ...catalogListDomain],
          'id'
        );
        catalogList = catalogListWithoutDuplicates.filter((element) => {
          return (
            state.filter.domainFilter.some((itemStoreArray) =>
              element.domain.includes(itemStoreArray)
            ) &&
            state.filter.industryFilter.some((itemStoreArray) =>
              element.industry.includes(itemStoreArray)
            )
          );
        });
      } else if (state.filter.industryFilter.length > 0 && state.filter.contentTypeFilter.length > 0) {
        const catalogListWithoutDuplicates = createArrayWithNoDuplicates(
          [...catalogListIndustry, ...catalogListContentType],
          'id'
        );
        catalogList = catalogListWithoutDuplicates.filter((element) => {
          return (
            state.filter.industryFilter.some((itemStoreArray) =>
              element.industry.includes(itemStoreArray)
            ) &&
            state.filter.contentTypeFilter.some((itemStoreArray) =>
              element.contentType.includes(itemStoreArray)
            )
          );
        });
      } else if (state.filter.domainFilter.length > 0 && state.filter.contentTypeFilter.length > 0) {
        const catalogListWithoutDuplicates = createArrayWithNoDuplicates(
          [...catalogListDomain, ...catalogListContentType],
          'id'
        );
        catalogList = catalogListWithoutDuplicates.filter((element) => {
          return (
            state.filter.domainFilter.some((itemStoreArray) =>
              element.domain.includes(itemStoreArray)
            ) &&
            state.filter.contentTypeFilter.some((itemStoreArray) =>
              element.contentType.includes(itemStoreArray)
            )
          );
        });
      }
      else if (state.filter.industryFilter.length > 0) {
        catalogList = catalogListIndustry;
      } else if (state.filter.domainFilter.length > 0) {
        catalogList = catalogListDomain;
      } else if (state.filter.contentTypeFilter.length > 0) {
        catalogList = catalogListContentType;
      }
      if (state.filter.nameFilter && state.filter.nameFilter.length > 0) {
        catalogList = catalogList.filter((element) => {
          return (
            element.displayName
              .toLowerCase()
              .includes(state.filter.nameFilter.toLowerCase()) !== false 
          );
        });
      }
      let catalogListCopy = catalogList.slice();
      switch (state.filter.sortingOrder) {
        case 1:
          catalogListCopy.sort((a, b) => {
            const date1 = new Date(a.modifiedOn);
            const date2 = new Date(b.modifiedOn);
            return date2 - date1;
          });
          break;
        case 2:
          catalogListCopy.sort((a, b) => {
            const date1 = new Date(a.modifiedOn);
            const date2 = new Date(b.modifiedOn);
            return date1 - date2;
          });
          break;
        case 3:
          catalogListCopy.sort((a, b) => {
            const nameA = a.displayName.toUpperCase();
            const nameB = b.displayName.toUpperCase();
            return nameA.localeCompare(nameB);
          });
          break;
        case 4:
          catalogListCopy.sort((a, b) => {
            const nameA = a.displayName.toUpperCase();
            const nameB = b.displayName.toUpperCase();
            return nameB.localeCompare(nameA);
          });
          break;
        default:
          break;
      }
      console.log("state.filteredCatalogList", state.filteredCatalogList);
      state.filteredCatalogList = [...catalogListCopy]
      console.log("catalogListCopy", [...catalogListCopy]);
    },
    resetInitialState: () => initialState,
  },
});

export const {
  AddToFilterArray,
  removeFromFilterArray,
  AddToSortArray,
  removeFromSortArray,
  AddToIndustryFilter,
  removeFromIndustryFilter,
  AddToDomainFilter,
  removeFromDomainFilter,
  removeAllFilters,
  updateNameFilter,
  resetInitialState,
  changePageNumber,
  filterCatalogList,
  updateSideBarFiltersArray,
  AddToContentTypeFilter,
  removeFromContentTypeFilter,
  resetDomainFilter,
} = filterSlice.actions;

export default filterSlice.reducer;