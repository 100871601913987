import React, {Component} from 'react';
import serverunavilable from '../../resources/images/server_unavailable.png';
//import classes from './serverunavilable2.module.css';
import classes from './ServiceUnavailable.module.css';
import { withTranslation } from 'react-i18next';
import  withRouter from "../../components/Common/withRouter";
class ServiceUnavailable extends Component {

    componentDidMount(){
        if (this.props.history.action!== "PUSH") {
            this.props.history.push("/package/visioninsights");
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className={classes.serverunavilable2}>
                <img src={serverunavilable} alt="serverunavilable" width="75px" height="75px" />
                <div className={classes.marginLeft20}>
                    <h1>{t("SERVER_UNAVAILABLE")}</h1>
                    <p>{t("EDGE_SOFTWARE_DOWNLOADS_ARE_TEMPORARILY_UNAVILABLE")}.</p>
                    <p>{t("WE_ARE_SORRY_FOR_THE_INCONVENIENCE")}.</p>
                    <p>{t("WHILE_YOU_ARE_WAITING_YOU_CAN_READ_MORE_ABOUT_THE_FULL_RANGE_OF_SOLUTION_AVILABLE_ON")} <a href="https://software.intel.com/en-us/iot/home" rel="noopener noreferrer" target="_blank">{t("INTEL_DEV_CATALOG")}</a> {t("OR_DIVE_INTO_OUR")} <a href ="https://software.intel.com/en-us/content/www/us/en/develop/documentation" rel="noopener noreferrer" target="_blank">  {t("DOCUMENTATION")}</a>  .</p>
                </div>
            </div>
        )
    }

}

export default (withTranslation() (withRouter(ServiceUnavailable)))