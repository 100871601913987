import configurator from '@intc/configurator';

configurator.register({
  HOST: [/localhost/],
  serviceURL: 'https://mcdevservicelayeresb.apps1-ir-int.icloud.intel.com/',
  oidc: {
    clientId: '422f51a9-771c-41aa-9305-bec720258d87',
    redirectUrl: '/'
  }
  
});
configurator.register({
  HOST: [/edge-services-catalog-dev-qa.apps1-bg-int.icloud.intel.com/],
  serviceURL: 'https://mcdevservicelayeresb.apps1-ir-int.icloud.intel.com/',
  oidc: {
    clientId: '422f51a9-771c-41aa-9305-bec720258d87',
    redirectUrl: '/'
  }
});
configurator.register({
  HOST: [/edge-services-catalog-qa-qa.apps1-bg-int.icloud.intel.com/],
  serviceURL: 'https://mcdevservicelayeresb.apps1-ir-int.icloud.intel.com/',
  oidc: {
    clientId: '422f51a9-771c-41aa-9305-bec720258d87',
    redirectUrl: '/'
  }
});
configurator.register({
    HOST: [/edge-services-catalog-qa-prod.apps1-bg-int.icloud.intel.com/],
    serviceURL: 'https://mc-qa-service-layer.apps1-ir-int.icloud.intel.com/',
    oidc: {
      clientId: '422f51a9-771c-41aa-9305-bec720258d87',
      redirectUrl: '/'
    }
});
configurator.register({
  HOST: [/edge-services-catalog-prod-qa.apps1-bg-int.icloud.intel.com/],
  serviceURL: 'https://eshqaservicelayer.intel.com/',
  oidc: {
    clientId: '422f51a9-771c-41aa-9305-bec720258d87',
    redirectUrl: '/'
  }
});
configurator.register({
  HOST: [/edgesoftwarecatalog.intel.com/],
  serviceURL: 'https://edgesoftwarehub.intel.com/',
  oidc: {
    clientId: '95af6dfe-125a-4a19-bfb8-2ab8fda5f2df',
    redirectUrl: '/'
  }
});
configurator.register({
  HOST: [/edgesoftwarecatalog-qa-qa.intel.com/],
  serviceURL: 'https://mcdevservicelayeresb.apps1-ir-int.icloud.intel.com/',
  oidc: {
    clientId: '422f51a9-771c-41aa-9305-bec720258d87',
    redirectUrl: '/'
  }
});
configurator.register({
  HOST: [/edgesoftwarecatalog-qa-prod.intel.com/],
  serviceURL: 'https://mcdevservicelayeresb.apps1-ir-int.icloud.intel.com/',
  oidc: {
    clientId: '422f51a9-771c-41aa-9305-bec720258d87',
    redirectUrl: '/'
  }
});


export default configurator.config;
