import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../src/components/HomePage';
import Header from '../src/components/common/Header';
import Footer from '../src/components/common/Footer';
import Overview from './components/Overview';
import SoftwareCatalog from './components/SoftwareCatalog';
import PageNotFound from './components/ErrorPage/pageNotFound';
import SelectorToolAppWrapper from './selector-tool-ui-src/SelectorToolAppWrapper';
import { loginRequest } from './authConfig';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser'; 

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/software-catalog" element={<SoftwareCatalog />} />
          <Route path="/details" element={<Overview />} />
          <Route path="/package/*" element={
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
              <SelectorToolAppWrapper />
            </MsalAuthenticationTemplate>
          } />
          <Route path="/PageNotFound" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
