import React, { Component } from "react";
import styles from "./AlertFailure.module.css";
class AlertFailure extends Component{
    state={
        isSwitchOn:false
    }

    render(){
        console.log(this.props);
        return(
           
            <div className={this.state.isSwitchOn ? "alert alert-danger alert-dismissible hide":"alert alert-danger alert-dismissible show"} role="alert">
            <div className={styles.fontname}>
              <strong> {this.props.message}</strong>
            <button type="button" class="close" data-dismiss="alert" onClick={()=>this.setState({isSwitchOn:true})}aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            </div>
        )
    }
}
export default AlertFailure;

