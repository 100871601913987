import { Section } from "@carbon/react";
import React from "react";
import './HeroBanner.scss'
 
export const HeroBanner = ({ children, variant, ...rest }) => {
 
    const secondaryGradientCheck = variant === 'secondary' ? { background: 'linear-gradient(160deg, #7000FF 8.99%, #040E35 73.45%)' } : null;
    return <Section
        style={{
            ...secondaryGradientCheck,
            padding: 'var(--cds-spark-theme-spacing-07)',
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            background: 'var(--hero-banner-bg-color)'
        }} {...rest}>
        {children}
        <style>
            {`
              :root {
    --hero-banner-bg-color: linear-gradient(
        135deg,
        #16cfb1 0%,
        #0054ae 17%,
        #5400c0 50%,
        #5400c0 60%,
        #000000 60%,
        #000000 61%,
        #040E35 61%,
        #7000FF 100%);
 
    @media (max-width: 480px) {
        --hero-banner-bg-color: linear-gradient(
            135deg,
            #16cfb1 0%,
            #0054ae 35%,
            #262FB6 55%,
            #000000 55%,
            #000000 56.5%,
            #300888 56.5%,
            #4D05BD 100%);
    }
  }
            `}
        </style>
    </Section>;
};