// import React from 'react';

const Footer = () => {
  window.INTELNAV = window.INTELNAV || {};
  window.INTELNAV.renderSettingsFooter = {
    version: '2.0 - 03/12/2017 08:00:00',
    OutputId: 'gf_default',
  };

  return <div id="recode50footer"></div>;
};

export default Footer;