// Copyright 2020 Intel Corporation.

// This software and the related documents are Intel copyrighted materials,
// and your use of them is governed by the express license under which they
// were provided to you ("License"). Unless the License provides otherwise,
// you may not use, modify, copy, publish, distribute, disclose or transmit
// this software or the related documents without Intel's prior written
// permission.

// This software and the related documents are provided as is, with no express
// or implied warranties, other than those that are expressly stated in the License.

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Recipes from '../../pages/Packages/Packages';
import CustomDownload from '../../pages/CustomDownload/CustomDownload';
import LicenseAgreement from '../../pages/LicenseAgreement/LicenseAgreement';
import DownloadRecipe from '../../pages/DownloadRecipe/DownloadRecipe';
import ServiceUnavailable from '../../pages/ServiceUnavailable/ServiceUnavailable';
import Page404 from '../../pages/404/page404';
import DownloadItep from '../../pages/DownloadItep/DownloadItep';
/* import ReferenceImplementation from "../../pages/Ri/Ri"; */

const AppRouter = () => (
  <Routes>
    {/* <Route exact path="/packages/:id" component={Recipes} /> */}
    <Route path="/customdownload" element={<CustomDownload />} />
    <Route path="/downloadrecipe/:id" element={<DownloadRecipe />} />
    <Route path="/licenseagreement/:id" element={<LicenseAgreement />} />
    <Route path="/serviceunavailable" element={<ServiceUnavailable />} />
    <Route path="/ri/:id" element={<Recipes />} />
    <Route path="404" element={<Page404 />} />
    <Route path="/:id" element={<Recipes />} />
    <Route path="/:id/download" element={<DownloadItep />} />
    <Route path="*" element={<Page404 />} />
  </Routes>
);
export default AppRouter;
