const filters = {
  industryFilters: [
    "Agriculture",
    "Banking and Financial Services",
    "Education",
    "Energy",
    "Government",
    "Health and Life Sciences",
    "Hospitality",
    "Manufacturing",
    "Transportation",
    "Telecommunications",
    "Media",
    "Retail",
    "Safety & Security",
    "Software & Services",
    "SmartCity",
  ],
  domainFilters: [
    "AI and Machine Learning",
    "Application Service",
    "Cloud Connector",
    "Communication Protocol",
    "Data Ingestion",
    "Device Service",
    "DevOps",
    "Intel® Tiber™ AI Suites",
    "Management Service",
    "Orchestration",
    "Visualization",
    'Enterprise AI',
    'Generative Artificial Intelligence (GenAI)',
  ],
  contentTypeFilters: ["Microservice", "Reference Implementation", "Sample Application", "Software Development Kits (SDKs)"],
};
const catalogUi = {
  maxLengthMicroserviceName: 56,
  maxLengthMicroserviceDescription: 143,
  maxLengthMicroserviceDescriptionForLandingPage: 100
};
const sortMenuOptions = [
  {
    id: 1,
    text: "Newest",
  },
  {
    id: 2,
    text: "Oldest",
  },
  {
    id: 3,
    text: "A-Z",
  },
  {
    id: 4,
    text: "Z-A",
  },
];
const svgMapping = [
  { contentType: "Microservice", icon: "iconMicroservice" },
  { contentType: "Reference Implementation", icon: "iconRI" },
  { contentType: "Sample Application", icon: "iconSample" }
];
const iconMapping = [
  { contentType: "Microservice", icon: "cube-detached-left" },
  { contentType: "Reference Implementation", icon: "build-cube" },
  { contentType: "Sample Application", icon: "cube" }
];
const toolTipMapping = [
  { contentType: "Microservice", toolTip: "A microservice is a small independent service with well-defined interfaces and operations." },
  { contentType: "Reference Implementation", toolTip: "Software-based downloads that enable a demo and or implementation example for a portion, a subsystem, or a system for a generic or specific application." },
  { contentType: "Sample Application", toolTip: "Sample Applications are for specific markets and show how to optimize, tune, and accelerate solutions for Intel® architecture." },
  { contentType: "Solution", toolTip: "A Solution is the application of an integrated combination of products (both hardware system and software) to solve an industry need or business problem. A solution is used for specific vertical or horizontal applications such as retail point of sale, weather forecasting, surveillance, cloud and virtualization." },
  { contentType: "Software Development Kits (SDKs)", toolTip: "Software Development Kits (SDKs) are a collection of software development tools that allow developers to create applications that work with a certain software package, software framework, hardware platform, operating system, or similar development platform." },
]
const apiUrl = {
  apigeeDev: "https://apis-dev.intel.com/edge-services-catalog/artifact-manager/v1",
  apigeePreQA: "https://apis-stage.intel.com/edge-services-catalog/artifact-manager/v1",
  apigeeQA: "https://apis-sandbox.intel.com/edge-services-catalog/artifact-manager/v1",
  apigeeProd: "https://apis.intel.com/edge-services-catalog/artifact-manager/v1"
};

const apigeeConfig = {
  devTokenUrl: "https://apis-dev.intel.com/v1/auth/token",
  stageTokenUrl: "https://apis-stage.intel.com/v1/auth/token",
  sandboxTokenUrl: "https://apis-sandbox.intel.com/v1/auth/token",
  prodTokenUrl: "https://apis.intel.com/v1/auth/token"
}

const apigeeUrl =
  typeof process.env.REACT_APP_AM_ENV === "undefined"
    ? apiUrl.apigeeDev
    : process.env.REACT_APP_AM_ENV === "DEV"
      ? apiUrl.apigeeDev
      : process.env.REACT_APP_AM_ENV === "PRE-QA"
        ? apiUrl.apigeePreQA
        : process.env.REACT_APP_AM_ENV === "QA"
          ? apiUrl.apigeeQA
          : process.env.REACT_APP_AM_ENV === "PROD"
            ? apiUrl.apigeeProd
            : apiUrl.apigeeQA;


const apigeeKey = 
  typeof process.env.REACT_APP_AM_ENV === "undefined"
    ? process.env.REACT_APP_DEV_KEY
    : process.env.REACT_APP_AM_ENV === "DEV"
      ? process.env.REACT_APP_DEV_KEY
      : (process.env.REACT_APP_AM_ENV === "PRE-QA" || process.env.REACT_APP_AM_ENV === "QA" || process.env.REACT_APP_AM_ENV === "PROD")
        ? process.env.REACT_APP_PROD_KEY
            : process.env.REACT_APP_PROD_KEY;

const apigeeSecret = 
  typeof process.env.REACT_APP_AM_ENV === "undefined"
    ? process.env.REACT_APP_DEV_SECRET
    : process.env.REACT_APP_AM_ENV === "DEV"
      ? process.env.REACT_APP_DEV_SECRET
      : (process.env.REACT_APP_AM_ENV === "PRE-QA" || process.env.REACT_APP_AM_ENV === "QA" || process.env.REACT_APP_AM_ENV === "PROD")
        ? process.env.REACT_APP_PROD_SECRET
            : process.env.REACT_APP_PROD_SECRET;

const tokenUrl =
  typeof process.env.REACT_APP_AM_ENV === "undefined"
  ? apigeeConfig.devTokenUrl
  : process.env.REACT_APP_AM_ENV === "DEV"
    ? apigeeConfig.devTokenUrl
    : process.env.REACT_APP_AM_ENV === "PRE-QA"
      ? apigeeConfig.stageTokenUrl
      : process.env.REACT_APP_AM_ENV === "QA"
        ? apigeeConfig.sandboxTokenUrl
        : process.env.REACT_APP_AM_ENV === "PROD"
          ? apigeeConfig.prodTokenUrl
          : apigeeConfig.sandboxTokenUrl;

const uiEnv = {
  qa: "qa",
  prod: "production",
};
const selfUIEnv =
  typeof process.env.REACT_APP_UI_ENV === "undefined"
    ? uiEnv.prod
    : process.env.REACT_APP_UI_ENV === "QA"
      ? uiEnv.qa
      : process.env.REACT_APP_UI_ENV === "PROD"
        ? uiEnv.prod
        : uiEnv.prod;
const catalogPagination = {
  itemsPerPage: 15,
};
const httpStatusCodes = {
  NOT_FOUND: 404,
};
const errorMessages = {
  notFoundMessage: 'Page Not Found',
};
module.exports = {
  filters,
  catalogUi,
  sortMenuOptions,
  apiUrl,
  apigeeUrl,
  selfUIEnv,
  uiEnv,
  catalogPagination,
  iconMapping,
  httpStatusCodes,
  errorMessages,
  toolTipMapping,
  apigeeConfig,
  apigeeKey,
  apigeeSecret,
  tokenUrl,
  svgMapping
};
