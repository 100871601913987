import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../components/Common/withRouter';
import { withTranslation } from 'react-i18next';
import { generateBreadcrumb } from '../../components/Common/common';
import { CodeSnippet } from '@carbon/react';
import { _acquireAccessToken } from '../../actions/axiosHelper';
import { Link } from '@carbon/react';
import './DownloadItep.scss';

class DownloadItep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadCommand: '',
            selectedRecipeType: null,
            userGuideLink: '',
            supportLink:
                'https://www.intel.com/content/www/us/en/support/contact-us.html#support-intel-products_67709:241149:241072:undefined',
            recipeName: '',
        };
    }

    componentDidMount = async () => {
        console.log(
            'DownloadItep called this.props.history.action',
            this.props.history.action
        );
        console.log(
            'DownloadItep called componentDidMount this.props.EIReducer',
            this.props.EIReducer,
            'this.props.EIReducer.recipes[0]',
            this.props.EIReducer.recipes[0]
        );
        if (this.props.EIReducer && this.props.EIReducer.recipes[0]) {
            console.log('DownloadItep called componentDidMount true case');
            let onlineDistroLicenseAccepted =
                this.props.EIReducer.onlineDistroLicenseAccepted;
            let selectedRecipeVersion = this.props.EIReducer.selectedRecipeVersion;
            let isCNDALicenseApproved = this.props.EIReducer.isCNDALicenseApproved;
            console.log(
                'DownloadItep called componentDidMount \nonlineDistroLicenseAccepted[selectedRecipeVersion]',
                onlineDistroLicenseAccepted[selectedRecipeVersion],
                '\nselectedRecipeVersion',
                selectedRecipeVersion,
                '\nisCNDALicenseApproved',
                isCNDALicenseApproved,
                '\nthis.props.EIReducer.licenceType',
                this.props.EIReducer.licenceType
            );
            if (
                (this.props.EIReducer.licenceType !== 'cnda' &&
                    onlineDistroLicenseAccepted[selectedRecipeVersion]) ||
                (this.props.EIReducer.licenceType === 'cnda' &&
                    isCNDALicenseApproved &&
                    onlineDistroLicenseAccepted[selectedRecipeVersion])
            ) {
                // if (this.props.history.action === "PUSH") {
                // this.props.UpdateNavigatedFromDownloadItep(true);
                this.setState({ selectedRecipeType: this.props.EIReducer.recipeClass });
                let downloadCommand =
                    this.props.EIReducer.ITEPDownloadList[0].consumption.en;
                console.log('DownloadItep downloadCommand', downloadCommand);
                let packageId = this.props.EIReducer.ITEPDownloadList[0].id;
                downloadCommand = downloadCommand.replace(
                    '<package-id>',
                    `${packageId}`
                );
                this.setState({ downloadCommand });
                await this.fetchAccessToken();
                const selectedRecipe = this.props.EIReducer.recipes.find(
                    (obj) => obj.id === this.props.EIReducer.selectedRecipeVersion
                );
                if (selectedRecipe) {
                    console.log(
                        'DownloadItep User Guide Link:',
                        selectedRecipe.userGuideLink
                    );
                    this.setState({ userGuideLink: selectedRecipe.userGuideLink });
                } else {
                    console.log(
                        'DownloadItep No matching object found with the given ID.'
                    );
                }
                const selectedLanguage = this.props.EIReducer.selectedLanguage;
            }
        } else {
            console.log(
                'DownloadItep called componentDidMount false case \nthis.props.EIReducer.baseURL',
                this.props.EIReducer.baseURL
            );
            let baseURL = this.props.EIReducer.baseURL;
            let splitURL = baseURL.split('/');
            let lastElement = splitURL[splitURL.length - 1];
            if (lastElement === 'download') {
                splitURL = splitURL.slice(0, -1).join('/');
                this.props.history.push(splitURL);
            } else {
                this.props.history.push(baseURL);
            }
        }
    };

    componentDidUpdate(prevProps) {
        // const hasAChanged =
        //     this.props.EIReducer.isLoaded !== prevProps.EIReducer.isLoaded;
        // if (hasAChanged) {
        //     if (this.props.EIReducer.isLoaded === true) {
        //         this.setState({ showLoader: false });
        //     } else {
        //         this.setState({ showLoader: true });
        //     }
        // }
    }

    fetchAccessToken = async () => {
        try {
            const accessToken = await _acquireAccessToken();
            this.setState({
                downloadCommand: this.state.downloadCommand
                    .replace('<jwt>', `${accessToken}`),
            });
            console.log(
                'DownloadItep fetchAccessToken called Access Token:',
                accessToken
            );
        } catch (error) {
            // Handle error scenarios
            console.error('Error acquiring access token:', error);
        }
    };

    render() {
        const { t } = this.props;
        return (
            this.props.EIReducer &&
            this.props.EIReducer.recipes[0] && (
                <>
                    {console.log('DownloadItep page render')}
                    <>
                        <section className="di-body">
                            <div className="di-page-section">
                                <p className="di-page-title">Get Started</p>
                                <div className="di-page-body">
                                    <div className="di-page-body-card-area">
                                        <div className="di-page-body-card-area-highlight-border"></div>
                                        <div className="di-page-body-card-section">
                                            <div className="di-page-body-card-text-content">
                                                <div className="di-page-body-card-title">
                                                    Installing
                                                </div>
                                                <div className="di-page-body-card-text">
                                                    Complete the prerequisite steps before installation.
                                                </div>
                                                <div className="di-page-body-card-title">
                                                    Product Key
                                                </div>
                                                <div className="di-page-body-card-sub-text">
                                                    Look for an email from Intel with the product key. You
                                                    will be prompted to enter your product key during
                                                    installation.
                                                </div>
                                                <div className="di-page-body-card-title">
                                                    Install Command
                                                </div>
                                                <div className="di-page-body-card-sub-text">
                                                    Copy the command and follow the instructions in the
                                                    documentation to start the installation. The command
                                                    is valid for 1 hour.
                                                    <CodeSnippet
                                                        className="di-code-snippet"
                                                        align="bottom"
                                                        feedback="Copied!"
                                                        type="multi"
                                                        maxCollapsedNumberOfRows={5}
                                                        wrapText
                                                    >
                                                        {this.state.downloadCommand}
                                                    </CodeSnippet>
                                                </div>
                                                {this.state.userGuideLink && (
                                                    <div className="di-page-body-card-info-text">
                                                        See the{' '}
                                                        <Link
                                                            href={this.state.userGuideLink}
                                                            target="_blank"
                                                            renderIcon={() => (
                                                                <i
                                                                    className="spark-icon spark-icon-external-link spark-icon-regular"
                                                                    style={{ fontFamily: 'spark-icon' }}
                                                                />
                                                            )}
                                                        >
                                                            Get Started Guide
                                                        </Link>{' '}
                                                        for detailed instructions.
                                                        {/* <a href={this.state.userGuideLink} target="_blank" rel="noreferrer">Get Started Guide
                                                <i
                                                    className="spark-icon spark-icon-external-link spark-icon-regular"
                                                    style={{ fontFamily: 'spark-icon' }}
                                                />
                                                </a> */}
                                                    </div>
                                                )}
                                                <hr className="di-page-body-card-line-with-shadow" />
                                                <div className="di-page-body-card-title">Support</div>
                                                <div className="di-page-body-card-info-text">
                                                    For '{this.props.EIReducer.EITitle.en ?? 'package'}'
                                                    issues, contact our{' '}
                                                    <Link
                                                        href={this.state.supportLink}
                                                        target="_blank"
                                                        renderIcon={() => (
                                                            <i
                                                                className="spark-icon spark-icon-external-link spark-icon-regular"
                                                                style={{ fontFamily: 'spark-icon' }}
                                                            />
                                                        )}
                                                    >
                                                        support team
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                </>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, {})(
    withTranslation()(withRouter(DownloadItep))
);
