import { ENSIGHT_TYPE } from "./../actions/types";

const initialState = {
  isFetchdataServiceCallRequired : true,
  EITitle: "",
  EIDesc: "",
  EIDownloadList: [],
  recipeVersions: [],
  osVersions: [],
  completeOSVersions: [],
  useCases: [],
  customisedIngedients: [],
  recipeId: "",
  recipes: [],
  selectedRecipeVersion: "",
  selectedOS: "",
  selectedOSName: "",
  selectedUseCase: "",
  selectedIngredients: [],
  selectedRecipeName: [],
  hardwareAndSystemRequirementLinks: "",
  isCustomizable: false,
  ircProductId: "",
  recipeClass: "vision",
  recipeURL: "visioninsights",
  documentationLink: "",
  userGuideLink: "",
  learnMoreLink: "",
  gettingStartedGuide: "",
  eId: "",
  productKey: "",
  isProductKeyRequired:true,
  downloadType: "default",
  session: {
    userName: "Profile",
    country: ""
  },
  otherFiles: "",
  isLoaded: true,
  resources: [],
  supportedResources: [],
  PackagingPercentage: 0,
  UpdateDisableRecipeList: "",
  selectedLanguage: "en",
  baseURL: "/",
  zipLabel: "",
  licenceType: "",
  isUserOptIn: true,
  isCNDALicenseApproved: false,
  isCNDArequestRaised: false,
  licensedata: [],
  isvalid: false,
  error: "",
  filterContent: [],
  selectedFilterOption: [],
  selectedFilterOptions: [],
  filterTags: [],
  companyName: "",
  isBannerRequired: true,
  supportLink: "https://software.intel.com/content/www/us/en/develop/articles/edge-software-hub-support.html",
  uniqueId: "",
  CNDAServiceResponse: false,
  selectedDistribution: "",
  UpdateServiceRequest:false,  
  isCNDAVerifyEntitlementCompleted: false,
  onlineDistroLicenseAccepted: {},
  isNavigatedFromLicenseAgreementPage: false,
  // isNavigatedFromDownloadItepPage: false,
  gtsBlockerResponse: false,
  gtsBlockerMsg: ''
};

export default function EIReducer(state = initialState, action) {
  console.log('EIReducer called action:', action, '\nstate', state)
  switch (action.type) {
    case ENSIGHT_TYPE:
      return {
        ...state,
        isFetchdataServiceCallRequired: false,
        EITitle: action.payload.EITitle,
        EIDesc: action.payload.recipes[0].desc,
        recipes: action.payload.recipes,
        recipeId: action.payload.recipes[0].id,
        EIDownloadList: action.payload.recipes[0].ingredients,
        ITEPDownloadList: action.payload.recipes[0].itepIngredients,
        recipeVersions: action.payload.versions,
        osVersions: action.payload.osList,
        completeOSVersions: action.payload.os,
        useCases: action.payload.useCases,
        customisedIngedients: action.payload.customisedIngedients,
        selectedRecipeVersion: action.payload.versions[0].id,
        selectedRecipeName: action.payload.versions[0].label,
        selectedUseCase: action.payload.useCases.length ? action.payload.useCases[0].id : '',
        selectedOS: action.payload.recipes[0].defaultOs,
        isProductKeyRequired:action.payload.isProductKeyRequired,
        otherFiles: action.payload.otherFiles,
        selectedOSName: action.payload.selectedOSName,
        selectedIngredients: action.payload.selectedIngredients,
        selectedIngredientsXML: action.payload.selectedIngredientsXML,
        isNoRecipe: false,
        isCustomizable: action.payload.recipes[0].isCustomizable,
        ircProductId: action.payload.recipes[0].ircProductId,
        zipLabel: action.payload.recipes[0].zipLabel || action.payload.recipeURL,
        recipeClass: action.payload.recipes[0].recipeTypeName.en,
        dependency: action.payload.dependency,
        documentationLink: action.payload.recipes[0].documentationLink,
        userGuideLink: action.payload.recipes[0].userGuideLink,
        learnMoreLink: action.payload.recipes[0].learnMoreLink,
        gettingStartedGuide: action.payload.recipes[0].gettingStartedGuide,
        resources: action.payload.resources,
        supportedResources: action.payload.supportedResources,
        baseURL: action.payload.baseURL,
        recipeURL: action.payload.recipeURL,
        licenceType: action.payload.licenceType,
        filterContent: action.payload.filterContent,
        filterTags: action.payload.filterTags,
        selectedFilterOption: action.payload.selectedFilterOption,
        selectedFilterOptions: action.payload.selectedFilterOptions,
        hardwareAndSystemRequirementLinks: action.payload.hardwareAndSystemRequirementLinks,
        companyName: action.payload.recipes[0].companyName,
        isBannerRequired: action.payload.recipes[0].isBannerRequired,
        supportLink: action.payload.recipes[0].supportLink
      };
    case "UpdateUserSelections":
      return {
        ...state,
        selectedOS: action.payload.selectedOS,
        selectedOSName: action.payload.selectedOSName,
        selectedUseCase: action.payload.selectedUseCase,
        selectedIngredients: action.payload.selectedIngredients,
        selectedIngredientsXML: action.payload.selectedIngredientsXML,
      };
    case "updateRecipeVersion":
      return {
        ...state,
        EIDownloadList: action.payload.ingredients,
        customisedIngedients: action.payload.customisedIngedients,
        selectedRecipeVersion: action.payload.selectedRecipeID,
        selectedRecipeName: action.payload.selectedRecipeName,
        isCustomizable: action.payload.isCustomizable,
        osVersions: action.payload.osList,
        supportedResources: action.payload.supportedResources,
        otherFiles: action.payload.otherFiles,
        documentationLink: action.payload.documentationLink,
        userGuideLink: action.payload.userGuideLink,
        learnMoreLink: action.payload.learnMoreLink,
        gettingStartedGuide: action.payload.gettingStartedGuide,
        zipLabel: action.payload.zipLabel,
        filterContent: action.payload.filterContent,
        filterTags: action.payload.filterTags,
        licenceType: action.payload.licenceType,
        selectedFilterOption: action.payload.selectedFilterOption,
        selectedFilterOptions: action.payload.selectedFilterOptions,
        dependency: action.payload.dependency,
        hardwareAndSystemRequirementLinks: action.payload.hardwareAndSystemRequirementLinks,
        EIDesc: action.payload.EIDesc,
        recipeId: action.payload.selectedRecipeID,
        ircProductId:action.payload.ircProductId
      };
    case "UpdateErrorMessage":
      return {
        ...state,
        isvalid: false,
        error: true,
        message: action.payload,
      };
    case "RemoveErrorMessage":
      return {
        ...state,
        error: false
      };
    case "UpdateRecipeClass":
      return {
        ...state,
        recipeClass: action.payload,
      };
    case "UpdateUseCaseSelection":
      return {
        ...state,
        selectedUseCase: action.payload,
      };
    case "updateFilterOption":
      return {
        ...state,
        selectedFilterOption: action.payload.selectedFilterOption,
        selectedFilterOptions: action.payload.selectedFilterOptions
      };
    case "UpdateDisableRecipeList":
      return {
        ...state,
        UpdateDisableRecipeList: action.payload,
      };
    case "UpdateOSSelection":
      return {
        ...state,
        selectedOS: action.payload.selectedOS,
        selectedOSName: action.payload.selectedOSText,
      };
    case "UpdateSelectedIngredients":
      return {
        ...state,
        selectedIngredients: action.payload.selectedIngredients,
        selectedIngredientsXML: action.payload.selectedIngredientsXML
      };
    case "UpdateCNDAStaus":
      return {
        ...state,
        isCNDALicenseApproved: action.payload.isCNDALicenseApproved,
        isCNDArequestRaised: action.payload.isCNDArequestRaised,        
        isCNDAVerifyEntitlementCompleted: true
      };
    case "NavigatedFromLicenseAgreementPage":
      return {
        ...state,
        isNavigatedFromLicenseAgreementPage: action.payload
      }
    // case "NavigatedFromDownloadItepPage":
    //     return {
    //       ...state,
    //       isNavigatedFromDownloadItepPage: action.payload
    //     }
    case "UpdateOdLicenseStatus":
      const temp = {...state.onlineDistroLicenseAccepted, ...action.payload}
      return {
        ...state,
        onlineDistroLicenseAccepted: temp
      }
    case "ClearOdLicenseStatus":
      return {
        ...state,
        onlineDistroLicenseAccepted: {}
      }
      case "GetLicenseData":
      return {
        ...state,
        licensedata: action.payload.licenseAgreement
      }

      case "UpdateCNDAServiceResponse":
        return {
          ...state,
          CNDAServiceResponse: action.payload,
          isCNDArequestRaised: action.payload === "success" ? true: false
        }

      case "UpdateGtsBlockerResponse":
        return {
          ...state,
          gtsBlockerResponse: action.payload
        }  
      
      case "UpdateGtsBlockerMsg":
        return {
          ...state,
          gtsBlockerMsg: action.payload
        }
    case "UpdateUserDetails":
      return {
        ...state,
        session: {
          userName: action.payload.username,
          country: action.payload.country
        },
      };
    case "UpdateOptInOption":
      return {
        ...state,
        isUserOptIn: action.payload,
      };
    case "ShowLoader":
      return {
        ...state,
        isLoaded: false,
      };
    case "HideLoader":
      return {
        ...state,
        isLoaded: true,
      };

    case "isNoRecipe":
      return {
        ...state,
        isNoRecipe: true,
      };
    case "RegisterProduct":
      return {
        ...state,
        eId: action.payload.eId,
        productKey: action.payload.productKey,
        uniqueId: action.payload.uniqueId
      };
    case "AddProductID":
      return {
        ...state,
        productKey: action.payload.productKey,
        downloadType: action.payload.downloadType,
        uniqueId: action.payload.uniqueId
      };
    case "UpdatePackagingPercentage":
      return {
        ...state,
        PackagingPercentage: action.payload,
      };
    case "UpdateDownloadType":
      return {
        ...state,
        downloadType: action.payload,
        selectedDistribution: action.payload
      };
    case "ClearRecipeDetails":
      return {
        ...state,
        isFetchdataServiceCallRequired: true,
        EITitle: "",
        EIDesc: "",
        EIDownloadList: [],
        recipeVersions: [],
        osVersions: [],
        useCases: [],
        customisedIngedients: [],
        recipes: [],
        selectedRecipeVersion: "",
        selectedOS: "",
        selectedUseCase: "",
        selectedIngredients: [],
        selectedIngredientsXML: [],
        filterContent: [],
        selectedisLoadedRequiredRecipeName: [],
        isLoaded: true,
        isNoRecipe: false,
        isCustomizable: false,
        ircProductId: "",
        eId: "",
        productKey: "",
        UpdateDisableRecipeList: "",
        PackagingPercentage: 0,
        zipLabel: "",
        isCNDALicenseApproved: false,
        isCNDArequestRaised: false,
        selectedFilterOption: [],
        selectedFilterOptions: [],
        isBannerRequired: true,
        uniqueId:"",
        licenceType: "",
        CNDAServiceResponse: false,
        gtsBlockerResponse: false,
        gtsBlockerMsg: "",
        selectedDistribution: "",
        supportLink: "https://software.intel.com/content/www/us/en/develop/articles/edge-software-hub-support.html",
        isCNDAVerifyEntitlementCompleted: false
      };
    case "SelectedLanguage":
      return {
        ...state,
        selectedLanguage: action.payload,
      };

      case "UpdateServiceRequest":
        return {
          ...state,
          UpdateServiceRequest: true,
        };
    default:
      return state;
  }
}