// import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddToSortArray, updateNameFilter } from '../../redux/reducers/slice/filterSlice';
import { sortMenuOptions } from '../../constants/config';
import { Dropdown, Search } from '@carbon/react'
import './Megabar.scss'
const Megabar = (props) => {
    const dispatch = useDispatch();
    const selectedIndex = useSelector(
        (state) => state.filter.filter.sortingOrder
    );
    const handleSearchQuery = e => {
        dispatch(updateNameFilter(e.target.value));
    };
    const handleSortBy = (item) => {
        if (item.selectedItem?.id && item.selectedItem.id !== selectedIndex)
            dispatch(AddToSortArray(item.selectedItem.id))
    }
    return (
        <>
        
            <div className='content_megabar'>
                <Dropdown
                    items={sortMenuOptions}
                    selectionMode="single"
                    aria-label="Sort By"
                    onChange={(selectedItem)=>{handleSortBy(selectedItem)}}
                    initialSelectedItem={sortMenuOptions[0]}
                    itemToString= {(item) => (item ? item.text : '')}
                    id='sortBy'
                    size="md"
                    className="content_sort_by"
                />
                <Search
                    closeButtonLabelText="Clear search input"
                    id="search-catalog"
                    labelText="Search"
                    placeholder="Search"
                    onChange={(e) => {handleSearchQuery(e)}}
                    size="md"
                    className='content_search'
                />
            </div>
       
        </>
    );
}
export default Megabar