import React from 'react';
import App from './App'; // Import the class-based App component
import store from './store';
import { Provider } from 'react-redux';
const SelectorToolAppWrapper = () => {
    return (
        <Provider store={store}>
                <App />
        </Provider>
    );
};
export default SelectorToolAppWrapper;
