import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading, Tag, Link } from "@carbon/react"
import {
    removeFromDomainFilter,
    removeFromIndustryFilter,
    removeAllFilters,
    filterCatalogList,
    updateSideBarFiltersArray,
    removeFromContentTypeFilter,
} from "../../redux/reducers/slice/filterSlice";
import CatalogCard from "../CatalogCard";
import "./CardLayout.scss";

const CardLayout = (props) => {
    const dispatch = useDispatch();

    const { catalogList, isLoading } = useSelector(
        (state) => state.homePageCatalog
    );
    const { filteredCatalogList } = useSelector((state) => state.filter);

    const [catalogCardList, setCatalogCardList] = useState(catalogList);
    const [chipList, setChipList] = useState([]);

    const industry = useSelector((state) => state.filter.filter.industryFilter);
    const topic = useSelector((state) => state.filter.filter.domainFilter);
    const contentType = useSelector(
        (state) => state.filter.filter.contentTypeFilter
    );
    const sortingOrder = useSelector((state) => state.filter.filter.sortingOrder);
    const nameFilter = useSelector((state) => state.filter.filter.nameFilter);

    const groupByName = (array) => {
		let groupedArray = [];
		array = JSON.parse(JSON.stringify(array));
		array.forEach((item) => {
			const collection = groupedArray.find((rec) => rec.microserviceNameForUrl === item.microserviceNameForUrl && rec.microserviceType === item.microserviceType);
			if (collection) {
				collection.allData.push(JSON.parse(JSON.stringify(item)));
			} else {
				item.allData = [JSON.parse(JSON.stringify(item))];
				groupedArray.push(item);
			}
		});
		return groupedArray;
	};
    useEffect(() => {
        if (catalogList) {
            let groupedList = groupByName(catalogList)
            setCatalogCardList(groupedList);
            dispatch(updateSideBarFiltersArray([groupedList,groupedList]));
        }
    }, [catalogList]);

    useEffect(() => {
        let groupedList = groupByName(catalogList)
        dispatch(filterCatalogList(groupedList));
    }, [industry, topic, contentType, nameFilter, sortingOrder]);

    useEffect(() => {
        let groupedList = groupByName(filteredCatalogList)
        
        setCatalogCardList(groupedList);
        dispatch(updateSideBarFiltersArray([groupedList, groupByName(catalogList)]));
    }, [filteredCatalogList, catalogList]);

    useEffect(() => {
        let temp = [];
        if (contentType.length > 0) {
            temp.push(
                ...contentType.map((item, index) => {
                    return { value: item, type: "contentType" };
                })
            );
        }
        if (industry.length > 0) {
            temp.push(
                ...industry.map((industryItem, index) => {
                    return { value: industryItem, type: "industry" };
                })
            );
        }
        if (topic.length > 0) {
            temp.push(
                ...topic.map((domainItem, index) => {
                    return { value: domainItem, type: "domain" };
                })
            );
        }
        setChipList(temp);
    }, [industry, topic, contentType]);

    const removeChip = (e, data) => {
        if (data.type === "industry")
            dispatch(removeFromIndustryFilter(data.value));
        else if (data.type === "domain")
            dispatch(removeFromDomainFilter(data.value));
        else if (data.type === "contentType")
            dispatch(removeFromContentTypeFilter(data.value));
    };

    const clearAllFilters = () => {
        dispatch(removeAllFilters());
    };
    return (
        <>
       
            {!isLoading ? <Heading className="spark-heading-size-s card-result-count">
                {catalogCardList.length} Results
            </Heading> : null}
            <div className="cardLayout_filter_chipset">
                {chipList.length > 0 ? (
                    <>
                        <Link className="clear-filter" onClick={clearAllFilters}>
                            Clear All Filters
                        </Link>                                        
                        {chipList.map((chip) => (
                             <Tag size="sm" filter onClose={(e) => removeChip(e, chip)} className="selected-filter-tags">
                                {chip.value}
                            </Tag>
                        ))}
                    </>
                ) : null}
            </div>
            <CatalogCard data={catalogCardList}></CatalogCard>
            
        </>
    );
};

export default CardLayout;
