import { msalInstance } from '../../index';
import { loginRequest } from '../../authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export async function _acquireAccessToken() {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    msalInstance.loginRedirect(loginRequest);
  }

  const request = {
    ...loginRequest,
    account: activeAccount || accounts[0],
  };
  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Handle the interaction required error
      msalInstance.loginRedirect(loginRequest);
    } else {
      // Handle other errors
      console.error(error);
      throw error; // Rethrow the error if it's not an interaction required error
    }
  }
}

export async function _acquireAuthResult() {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    msalInstance.loginRedirect(loginRequest);
  }

  const request = {
    ...loginRequest,
    account: activeAccount || accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult
}
const configureAxios = async (axios) => {
  console.log('called configureAxios')
  axios.interceptors.request.use(async (config) => {
    const accessToken = await _acquireAccessToken(msalInstance);
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  });
};

export default configureAxios;